import React, { useEffect, useState } from 'react';
import { findDateQuery, toEnglishDigits, toFarsiNumber, zeroFormat } from '../../../helpers/tools';
import moment from 'jalali-moment';
import _ from 'lodash';
import FetchListLoad from '../../../components/FetchListLoad';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import FetchListLoadSmall from '../../../components/FetchListLoadSmall';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { statusFA } from '../../../config/variable';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { menu } from '../../../config/menu';
import NoticeList from '../../../components/NoticeList';
import * as DrawerState from '../../../services/redux/drawer';
import * as MenuState from '../../../services/redux/menu';

const TableTraNotBox = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [listTras, setListTras] = useState([]);
  const [loadHisTras, setLoadHisTras] = useState(false);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  const [tabSelect, setTabSelect] = useState("depo");
  const [tabSelectNotif, setTabSelectNotif] = useState("private");
  const alarmApi: any = useSelector((state: any) => state.publicApis?.alarmApi);
  const noticeApi: any = useSelector((state: any) => state.publicApis?.noticeApi);


  const get = async () => {
    setLoadHisTras(true);
    setListTras([])

    const result = tabSelect == "depo"
      ? await gather(`${config.api}/v1/deposit?sortBy=createdAt&orderBy=desc&page=1&fromDate=${from}&toDate=${to}&limit=3`).get()
      : tabSelect == "cashout"
        ? await gather(`${config.api}/v1/withdraw?sortBy=createdAt&orderBy=desc&page=1&fromDate=${from}&toDate=${to}&limit=3`).get()
        : await gather(`${config.api}/v1/order?sortBy=createdAt&orderBy=desc&page=1&fromDate=${from}&toDate=${to}&limit=3`).get()

    if (result.message === 200) {
      setListTras(result?.data ?? []);

    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHisTras(false);
  }

  useEffect(() => {
    if (userIsLogin) {
      get();
    }
  }, [tabSelect]);

  return (
    <div className='tableTra_NotBox'>
      <div className='cardTableTrans'>
        <div className='boxTitle'>
          <p className='t1'>تاریخچه اخیر</p>
          {/* <NavLink
            to={tabSelect == "depo"
              ? menu.notifications.url
              : tabSelect == "cashout"
                ? menu.notifications.url
                : menu.notifications.url
            }
            className='t2 cPointer'
          >نمایش همه</NavLink> */}
        </div>
        <div className='boxtabs'>
          <button className={`btn ${tabSelect == "depo" ? "active" : ""}`} onClick={() => setTabSelect("depo")}>
            <span className='icon-deposit1'></span>
            واریزها
          </button>
          <button className={`btn ${tabSelect == "cashout" ? "active" : ""}`} onClick={() => setTabSelect("cashout")}>
            <span className='icon-witdraw1'></span>
            برداشت ها
          </button>
          <button className={`btn ${tabSelect == "order" ? "active" : ""}`} onClick={() => setTabSelect("order")}>
            <span className='icon-IconRepeat'></span>
            معاملات
          </button>
        </div>
        <div className='tb_View tb_listTrans'>
          <table>
            <thead>
              <tr>
                <th><h5>رمز ارز</h5></th>
                <th><h5>مقدار</h5></th>
                <th><h5>تاریخ</h5></th>
                <th><h5>وضعیت</h5></th>
              </tr >
            </thead >
            <tbody className={"relative"}>
              {(!loadHisTras && !_.isEmpty(listTras))
                && Array.from(listTras)?.map((i: any, n: any) =>
                  <tr key={n} className="box itemBody">
                    <td className='nameCoin'>
                      <LazyLoadImage crossOrigin='anonymous' className='iconCoin' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" />
                      {tabSelect == "order"
                        ? <p className='t1'>{i?.coin?.nameFa}/<b>{i?.pair == "tmn" ? "تومان" : "تتر"}</b></p>
                        : <p className='t1'>{i?.coin?.nameFa} <b>({i?.coin?.symbol})</b></p>}
                    </td>
                    <td className='amount'>{zeroFormat(i?.amount)}</td>
                    <td className='date ltr'>{i?.createdAt ? toEnglishDigits(moment(i?.createdAt).locale("fa").format('  YYYY-MM-DD HH:mm')) : "---"}</td>
                    <td className={`status ${i?.status}`}>{statusFA[i?.status]}</td>
                  </tr>)}
            </tbody>
          </table >
          <FetchListLoadSmall list={listTras} load={loadHisTras} />
        </div >
      </div>
      <div className='cardTableNotif'>
        <div className='boxTitle'>
          <p className='t1'>اطلاعیه ها</p>
          <div
            onClick={() => {
              tabSelectNotif == "update" ?
                dispatch(DrawerState.showMediumL(
                  <NoticeList close={() => dispatch(DrawerState.hide())} />
                ))
                : navigate(menu.notifications.url); dispatch(MenuState.hide())
            }}
            className='t2 cPointer'>همه اطلاعیه ها</div>
        </div>
        <div className='boxtabs'>
          <button className={`btn ${tabSelectNotif == "private" ? "active" : ""}`} onClick={() => setTabSelectNotif("private")}>
            خصوصی
          </button>
          <button className={`btn ${tabSelectNotif == "public" ? "active" : ""}`} onClick={() => setTabSelectNotif("public")}>
            عمومی
          </button>
          <button className={`btn ${tabSelectNotif == "update" ? "active" : ""}`} onClick={() => setTabSelectNotif("update")}>
            بروزرسانی ها
          </button>
        </div>
        <div className='listNotif'>
          {((tabSelectNotif == "private" && alarmApi?.filter((ele: any) => ele?.user)?.length == 0) ||
            (tabSelectNotif == "public" && alarmApi?.filter((ele: any) => !ele?.user)?.length == 0)) ||
            (tabSelectNotif == "update" && noticeApi?.length == 0)
            ? <div className='emptyLoad' style={{ margin: "auto" }}>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" />
              <p>موردی یافت نشد</p>
            </div>
            : Array.from(tabSelectNotif == "update"
              ? noticeApi : alarmApi?.filter((ele: any) => {
                if (tabSelectNotif == "private") {
                  return ele?.user
                } else {
                  return !ele?.user
                }
              })
            ).slice(0,3)?.map((i: any, n: number) =>
              <div className='item cPointer' key={n}
                onClick={() => {
                  tabSelectNotif == "update" ?
                    dispatch(DrawerState.showMediumL(
                      <NoticeList close={() => dispatch(DrawerState.hide())} />
                    ))
                    : navigate(menu.notifications.url); dispatch(MenuState.hide())
                }}
              >
                <div className='iconLogo'>
                  <span className='icon-logoGray'></span>
                </div>
                <div className='boxDetail'>
                  <div className='txtBox'>
                    <p className='t1'>{i?.text ? i?.text?.slice(0, 50) : "- - -"}{i?.text?.length > 80 ? " . . ." : ""}</p>
                    <p className='t2 ltr'>{moment(i?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
                  </div>
                  <span className='icon-ArrowLeft'></span>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default TableTraNotBox;
