import { DialogContent } from '@mui/material';
import moment from 'jalali-moment';

const PhishingWarning = ({ text, close, expire }: { text: any, expire: any, close: any }) => {

  return (
    <DialogContent className='phishingWarning'>
      <div className='body'>
        <div className='topTxt'>
          {/* <span className='iconRed-document'></span> */}
          <p className='t1'>⚠️ محدودیت برداشت رمزارزی ( به دستور پلیس فتا ) </p>
        </div>
        <div className='divider'></div>
        <div className='warningText mb-8'>
          {text} ، برداشت رمزارز از حساب شما به‌صورت موقت غیرفعال شده است. این محدودیت پس از ۲۴ ساعت به‌صورت خودکار رفع خواهد شد.
        </div>
        <div className='warningText'>
        نیازی به تماس با پشتیبانی نیست. لطفاً صبور باشید تا محدودیت به‌طور خودکار برداشته شود.
        </div>
        <div className='btnsTxt'>
          <button className="finalCheckBtn" onClick={() => close()} > متوجه شدم  </button>
          <div className='txtbox'>
            <p className='t2 ltr'>{expire ? moment(expire).locale("fa").format('YYYY-MM-DD - HH:mm ') : "---"}</p>
            <p className='t2 '>🕘 زمان رفع محدودیت :</p>
          </div>
        </div>
      </div>
    </DialogContent>
  )
}

export default PhishingWarning