import React, { memo, useEffect, useState } from 'react'
import * as MenuState from '../../services/redux/menu';
import * as DialogShow from '../../services/redux/dialog';
import { useDispatch, useSelector } from 'react-redux';
import Notifcation from './Notifcation';
import { verifyFA } from '../../config/variable';
import { NavLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'jalali-moment';
import UserProfileHeader from './UserProfileHeader';
import SandwichMenu from './SandwichMenu';
import MenuWallet from './menu/MenuWallet';
import { useLocalStorage } from 'usehooks-ts';
import { SecurityAlarmOutline } from 'react-icons-sax';
import * as DrawerState from '../../services/redux/drawer';
import NoticeList from '../NoticeList';
import * as DataSaver from '../../services/redux/dataSaver';
import AddEmail from '../../pages/Profile/dialog/AddEmail';
import QustionAddOrEditEmail from '../../pages/Profile/dialog/QustionAddOrEditEmail';

const Header = ({ inMarket = false, title = "داشبورد" }: any) => {
  const dispatch = useDispatch<any>();
  const profile = useSelector((state: any) => state.publicApis?.profile);
  const alarmApi: any = useSelector((state: any) => state.publicApis?.alarmApi);
  const [theme, setTheme] = useLocalStorage('themeEX', "light");
  const [warningRule, setWarningRule] = useLocalStorage('alertRule', "");
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const navigati = useNavigate();
  const [countAlarm, setcountAlarm] = useState(0);


  const menuShow = (e: React.MouseEvent<HTMLButtonElement>, ly: any, horiz: string = "right") => {
    dispatch(MenuState.show({
      content: ly,
      anchorEl: e.currentTarget,
      horizontal: horiz
    }))
  }

  const handelAddEditEmail = async () => {
    if (profile?.email) {
      dispatch(DialogShow.show(<QustionAddOrEditEmail value={profile?.email} />))
    } else {
      dispatch(DialogShow.show(<AddEmail />))
    }
  }

  // const transition = React.forwardRef(function Transition(
  //   props: TransitionProps & {
  //     children: React.ReactElement;
  //   },
  //   ref: React.Ref<unknown>,
  // ) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  const countAlert = () => {
    let count = 0;
    Array.from(alarmApi)?.forEach((i: any) => {
      if (profile?.readNotif) {
        if (i?.createdAt > profile?.readNotif) {
          count += 1
        }
      }
    })
    setcountAlarm(count)
  }
  useEffect(() => {
    countAlert()
  }, [profile, alarmApi]);

  return (
    <header className='newHeader'>
      <div className='topHeader'>
        <div className='titlePageDate'>
          {!inMarket && <SandwichMenu />}
          <h2>{title}</h2>
          <p>امروز {moment().locale("fa").format('dddd D MMMM YYYY')}</p>
        </div>
        {userIsLogin
          ? <div className='menuControl'>
            <div className="wallet hover" >
              <span className='icon-wallet'></span>
              <p>کیف پول</p>
              <div className="wallet" >
                <span className='icon-Arrow2 cPointer'></span>
              </div>
              <div className='menuHover'>
                <MenuWallet />
              </div>
            </div>
            <div className='dividerhorizontal respons750' ></div>
            <span onClick={() => setTheme(theme == "light" ? "dark" : "light")} className='icon-sun cPointer'></span>
            <div className='dividerhorizontal respons550' ></div>
            <div className='noticeBtn'
              onClick={() => dispatch(DrawerState.showMediumL(
                <NoticeList close={() => dispatch(DrawerState.hide())} />
              ))}
            // onClick={(e: any) => menuShow(e, <NoticeList close={() => dispatch(MenuState.hide())} />, "center")}
            >
              <SecurityAlarmOutline size={20} />
            </div>
            <div className='dividerhorizontal respons550' ></div>
            <div className=" ringdrop" onClick={(e: any) => menuShow(e, <Notifcation setCount={setcountAlarm} />, "center")} >
              <span className={`icon-bell notify-icon ${countAlarm > 0 && "anim"}`}></span>
              <span className="w3-count">{countAlarm > 10 ? "+10" : countAlarm}</span>
            </div>
            <div className='dividerhorizontal' ></div>
            <UserProfileHeader />
          </div>
          : <div className='menuControl'>
            <span onClick={() => setTheme(theme == "light" ? "dark" : "light")} className='icon-sun cPointer'></span>
            <div className='dividerhorizontal respons550' ></div>
            <div className="signinOrLogin btnLogin"
              onClick={() => {
                navigati("/login")
                dispatch(DataSaver.changeUserIsReady(false));
                // setActiveLogin("true");
              }}>
              <span className='icon_login2'></span>
              <h4>ورود</h4>
            </div>
            <div className="signinOrLogin"
              onClick={() => {
                navigati("/register")
                dispatch(DataSaver.changeUserIsReady(false));
                // setActiveLogin("true");
              }}>
              <span className='icon_User3'></span>
              <h4>ثبت نام</h4>
            </div>
          </div>
        }

      </div>
      {(warningRule == "true" && userIsLogin)
        && <div className='alertRule'>
          <span className='iconDangerRed2'></span>
          <p>
            کاربر گرامی، آگهی‌های کاریابی که در سایت‌ها یا شبکه های اجتماعی با موضوع ”استخدام ادمین“ یا موارد مشابه به منظور تبدیل ریال به ارز دیجیتال از سوی شخص حقیقی یا حقوقی منتشر می شوند، قطعا کلاهبرداری هستند. لذا هرگونه اقدام کاربران در این زمینه به منزله‌ی پول‌شویی تلقی می شود. با توجه به این که مبالغ واریزی برای تبدیل ارز، با روش‌های متقلبانه از حساب افراد برداشت می‌شود، بنابراین مسئولیت کیفری وهمچنین جبران خسارت مال باختگان برعهده شخص است.
          </p>
          <button className='okWarningBtn' onClick={() => setWarningRule("false")}>متوجه شدم</button>
        </div>}
      <div className="alertBoxAuth">
        {profile?.verify && Object?.keys(profile?.verify).map((i: any, n: number) => {
          if ((i == "email" || i == "identity") && !profile?.verify[i]) {
            return <div key={n} className='item'>
              <div className='textAut'>
                <span className={`iconRed-${i}`}></span>
                <h5>{verifyFA[i]?.text}</h5>
                <p>{verifyFA[i]?.text} شما تایید نشده لطفا نسبت به تایید اقدام کنید</p>
              </div>
              {i == "email"
                ? <button className='submit' onClick={handelAddEditEmail}>تایید {verifyFA[i]?.text}</button>
                : <NavLink className='submit' to={verifyFA[i]?.goTo}>تایید {verifyFA[i]?.text}</NavLink>}
            </div>
          }
          return ''
        })}

      </div>
    </header>
  )
}

export default memo(Header)