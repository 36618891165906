import React, { useState } from 'react';

const TicketOnline = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="support-container">
    {/* دکمه باز و بسته کردن */}
    <button onClick={() => setIsOpen(!isOpen)} className="support-button">
    پشتیبانی اکسنوین
    <span className='icon-support'></span>
    </button>

    {/* پنجره شناور */}
    {isOpen && (
      <div className="support-popup iphone-size">
        <div className="support-header">
          <button onClick={() => setIsOpen(false)} className="close-button">✕</button>
          <span className='t1'>پشتیبانی اکسنوین</span>
        </div>
        {/* نمایش وب ویو بدون نمایش URL */}
        <iframe
          srcDoc={`<html><head><style>body{margin:0;padding:0;overflow:hidden;}</style></head><body><iframe src='https://exnovin.net/dl/api/live.html' style='width:100%; height:100vh; border:none;'></iframe></body></html>`}
          className="support-iframe"
        ></iframe>
      </div>
    )}
  </div>
  );
}

export default TicketOnline;
