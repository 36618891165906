import { useSelector } from 'react-redux';
import useTicker from './useTicker';
import _ from 'lodash';

const useCoinPrice = () => {
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const listAllPairObj = useSelector((state: any) => state.publicApis?.listAllPairObj);
  const ticker = useTicker();

  const coinsList = () => {
    const coins = Object.values(listCoinObj).map((e: any) => {
      let newItem = _.cloneDeep(e);
      newItem["doolarPrice"] = e.symbol == "usdt" ? 1 : ticker.price(e.symbol + 'usdt', 1)
      newItem["tmnPrice"] = ticker.price(e.symbol + 'tmn', 1)
      return newItem
    });
    return coins.reduce((a, v) => ({ ...a, [v.symbol]: v }), {}) as { [key: string]: any; }
  }

  const coinsPairList = () => {
    const coinsPair = Object.values(listAllPairObj).map((e: any) => {
      let newItem = _.cloneDeep(e);
      newItem["doolarPrice"] = e.symbol == "usdt" ? 1 : ticker.price(e.symbol + 'usdt', 1);
      newItem["tmnPrice"] = ticker.price(e.symbol + 'tmn', 1)
      return newItem
    });
    return coinsPair.reduce((a, v) => ({ ...a, [v.pairSymbol]: v }), {}) as { [key: string]: any; }
  }

  return { coinsList, coinsPairList };
};

export default useCoinPrice;
