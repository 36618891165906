import _ from "lodash"
import ItemCoinGift from "./ItemCoinGift"

const ListCoinGift = ({ listData, onClick, handleAdd, className }: any) => {

  return (
    <div className={`listCoinPopup listCoinGift ${className}`} onClick={onClick}>
      {_.isEmpty(listData)
        ? <div className="emptyListGift">
          <img src="/icons/m-IconNotingList2.svg" alt="" />
          <p>در حال حاضر هیچ موجودی تومانی و رمزارزی در کیف پول خود ندارید</p>
        </div>
        : listData?.map((i: any, n: any) =>
          <ItemCoinGift key={n} data={i} handleAdd={handleAdd} />
        )}
    </div>
  )
}

export default ListCoinGift