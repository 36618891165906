import NavChangeDeposit from './items/NavChangeDeposit';
import { useEffect, useState } from 'react';
import History from './items/History';
import _ from 'lodash';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';
import useSnack from '../../hooks/useSnack';
import { NavLink } from 'react-router-dom';
import CustomSelectBoxLabel from '../../components/CustomSelectBoxLabel';
import { useSelector } from 'react-redux';
import { detect_bank, detect_bankName } from '../../helpers/bankName';

const DepositCashCard = () => {
  const snackUse = useSnack();
  const cardBanckList = useSelector((state: any) => state.user.cards);
  const [card, setCard]: any = useState(Object.values(cardBanckList)[0] ?? {});
  const profile: any = useSelector((state: any) => state.user?.profile);
  const listLevelObjByID: any = useSelector((state: any) => state.publicApis?.listLevelObjByID);

  useEffect(() => {
    document.title = menu.financial.childs.depositAll.childs.depositCash.childs.card.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox'>
                <NavChangeDeposit />
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>1</span></p>
                    <h5>انتخاب کارت مبدا</h5>
                  </div>
                  <NavLink className="addCard" to={menu.userBank.childs.account.url}>
                    <span className='icon-IconAddCard'></span>
                    <p>افزودن کارت</p>
                  </NavLink>
                </div>
                <CustomSelectBoxLabel element={
                  <div className='showBanck'>
                    {card?.number
                      ? <><h4 className='p-0 fontCard' >{card?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</h4>
                        <img className='m-0' src={`${!_.isEmpty(detect_bank(card?.number).bank_logo) ? detect_bank(card?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
                      </>
                      : <h4 className='defaultText'><b>شماره کارت خود را انتخاب کنید</b></h4>}
                  </div>}
                  error={false} listData={Object.values(cardBanckList) ?? []} label={"شماره کارت"} setInputSatet={setCard} className={" styleBankSelect mt-10"} />
                <p className='cashCardAlert'>شماره کارت باید به نام خودتان باشد و در اکسنوین ثبت شده باشد</p>
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>2</span></p>
                    <h5> شماره کارت مقصد</h5>
                  </div>
                </div>
                {listLevelObjByID[profile?.plane?.level?._id]?.cardToCard
                  ?.filter((ele: any) => ele?.isActive)
                  ?.map((i: any, n: any) =>
                    <div className='generatedID' key={n}>
                      <div className='item'>
                        <p>وضعیت</p>
                        <span className='dividerDash'></span>
                        <p className='colorGreen'>فعال</p>
                      </div>
                      {i?.card &&
                        <div className='item'>
                          <p>شماره کارت مقصد</p>
                          <span className='dividerDash'></span>
                          <div className='imgOpatrtor'>
                            <CopyToClipboard text={i?.card} onCopy={() => { snackUse.showSaccess("شماره کارت کپی شد") }}>
                              <span className='icon-IconCopy2 cPointer'></span>
                            </CopyToClipboard>
                            <p>{i?.card}</p>
                            <img src={`${detect_bankName(i?.slug).bank_logo}`} alt="" />
                          </div>
                        </div>}

                      {i?.account &&
                        <div className='item'>
                          <p>شماره حساب مقصد</p>
                          <span className='dividerDash'></span>
                          <div className='imgOpatrtor'>
                            <CopyToClipboard text={i?.account} onCopy={() => { snackUse.showSaccess("شماره حساب کپی شد") }}>
                              <span className='icon-IconCopy2 cPointer'></span>
                            </CopyToClipboard>
                            <p>{i?.account}</p>
                            <img src={`${detect_bankName(i?.slug).bank_logo}`} alt="" />
                          </div>
                        </div>
                      }
                      {i?.sheba &&
                        <div className='item'>
                          <p>شماره شبا مقصد</p>
                          <span className='dividerDash'></span>
                          <div className='imgOpatrtor'>
                            <CopyToClipboard text={i?.sheba} onCopy={() => { snackUse.showSaccess("شماره شبا کپی شد") }}>
                              <span className='icon-IconCopy2 cPointer'></span>
                            </CopyToClipboard>
                            <p>{i?.sheba}</p>
                            <img src={`${detect_bankName(i?.slug).bank_logo}`} alt="" />
                          </div>
                        </div>
                      }
                      <div className='item'>
                        <p>نام صاحب کارت</p>
                        <span className='dividerDash'></span>
                        <div className='imgOpatrtor'>
                          <p>{i?.owner ?? "---"}</p>
                        </div>
                      </div>
                      <div className='item'>
                        <p>نام بانک</p>
                        <span className='dividerDash'></span>
                        <div className='imgOpatrtor'>
                          <p>{i?.bank ?? "---"}</p>
                        </div>
                      </div>
                      {/* {n + 1 < listLevelObjByID[profile?.plane?.level?._id]?.cardToCard?.length
                      &&
                      <div className='divderCardBank'></div>
                    } */}
                    </div>
                  )}
              </div>
              <div className='detailBox'>
                <img className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                <div className='minDepoText denger1'>
                  <img src="/icons/danger.svg" alt="" />
                  <p>برای واریز لطفا از سامانه پل استفاده نکنید. در صورت واریز مبلغ شارژ نخواهد شد.</p>
                </div>
                <div className='infoText'>
                  <h5><b>نکات</b> مهم</h5>
                  <p>لطفا دقت کنید کارت بانکی مبدا بنام شما و حتما در پروفایل کاربری شما ثبت و تاییده شده باشد.</p>
                  <p>سقف واریزی روزانه از هر کارت<span className='colorRed'> 10,000,000 تومان </span>  است. به طور مثال : از 5 کارت مختلف میتوانید 5 بار 10 میلیون واریز کنید.</p>
                  <p>پس از انجام کارت به کارت ، تا <span className='colorRed'> 5 دقیقه </span> منتظر بمانید تا سیستم به صورت خودکار واریزی شما را شناسایی و مبلغ به کیف پول شما اضافه کند.</p>
                  <p>سعی کنید از طریق <span className='colorRed'>اپلیکیشن های همراه بانک یا عابر بانک ATM</span> ، کارت به کارت کنید. متاسفانه از اپ های ماننده آپ ، سکه ، همراه کارت و ... محدودیت های مختلفی دارند.</p>
                </div>
                <div className='infoText'>
                  <h5>کارمزد 0% کارت به کارت</h5>
                  <p>در حال حاضر کارمزد سرویس کارت به کارت  رایگان است.</p>
                </div>
              </div>
            </div>
            <History flag={true} reload={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DepositCashCard
