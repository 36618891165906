import React from 'react'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'
import { Fade, Tooltip } from '@mui/material'

const NavChangeHistory = () => {
  return (
    <div className='changeNavStyle'>
      <NavLink to={menu.financial.childs.transactionHistory.childs.order.url} className='item'>سفارشات</NavLink>
      <NavLink to={menu.financial.childs.transactionHistory.childs.depositCash.url} className='item'>واریز تومان</NavLink>
      <NavLink to={menu.financial.childs.transactionHistory.childs.withdrawCash.url} className='item'>برداشت تومانی</NavLink>
      <NavLink to={menu.financial.childs.transactionHistory.childs.depositCoin.url} className='item'>واریز ارزی </NavLink>
      <NavLink to={menu.financial.childs.transactionHistory.childs.withdrawCoin.url} className='item'>برداشت ارزی </NavLink>
      <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} enterDelay={500} leaveDelay={200}
        title="... به زودی">
        <div className='item'>دستگاه های فعال </div>
      </Tooltip>
    </div>
  )
}

export default NavChangeHistory