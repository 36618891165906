import { Checkbox, CircularProgress, DialogContent } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import { toFixed, zeroFormat } from '../../../helpers/tools';
import moment from 'jalali-moment';
import { sideFA } from '../../../config/variable';
import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import * as BackdropShow from '../../../services/redux/backdrop';
import { useDispatch } from 'react-redux';
import useTicker from '../../../hooks/useTicker';

const ConfirmBuySellFast = ({ data, send, close }: { data: any, send: any, close: any }) => {
  const [myLoading, setMyLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confiemOrder, setConfiemOrder] = useLocalStorage('confiemOrder', "false");
  const ticker = useTicker();

  const hideDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setConfiemOrder(event.target.checked + "")
  }

  const handelSend = () => {
    setMyLoading(true)
    send({
      'coin': data?.coin._id,
      'pair': 'tmn',
      'side': data?.side,
      'price': ticker[data?.side == "buy" ? "buy" : "sell"](data?.coin?.symbol + 'tmn'),
      "amount": data?.side == "buy"
        ? toFixed(data?.myBuySell / ticker['buy'](data?.coin?.symbol + 'tmn'), data?.coin?.decimal)
        : toFixed(data?.amount, data?.coin?.decimal)
      ,
      "trade": "otc",
      "stop": null
    })

  }

  return (
    <DialogContent className='detailWithdraw'>
      <div className='body'>
        <div className='dec'>
          <p>نوع سفارش</p>
          <p className={data?.side == "buy" ? "colorGreen" : "colorRed"}>{sideFA[data?.side]}</p>
        </div>
        {/* <div className='dec'>
          <p> نحوه سفارش</p>
          <p >سفارش سریع</p>
        </div>
        <div className='dec'>
          <p>تاریخ</p>
          <p>{moment().locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
        </div> */}
        <div className='dec'>
          <p>رمز ارز</p>
          <div className='typeCoin'>
            <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
            <p className='ltr'>{data?.coin?.symbol?.toUpperCase() ?? "تومان"}</p>
          </div>
        </div>
        <div className='dec'>
          <p>مقدار </p>
          <p className='ltr'>
            {data?.side == "buy"
              ? zeroFormat(toFixed(data?.myBuySell / ticker['buy'](data?.coin?.symbol + 'tmn'), data?.coin?.decimal))
              : zeroFormat(toFixed(data?.amount, data?.coin?.decimal))}
            &nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
        </div>
        <div className='dec'>
          <p>قیمت</p>
          <p className='ltr'>{zeroFormat(ticker[data?.side == "buy"?"buy":'sell'](data?.coin?.symbol + 'tmn'))}</p>
        </div>
        <div className='dec'>
          <p>مجموع</p>
          <p className='ltr'>
            {data?.side == "buy"
              ? zeroFormat(toFixed(data?.myBuySell, data?.side == "buy" ? 0 : data?.coin?.decimal))
              : zeroFormat(toFixed(data?.amount * ticker['sell'](data?.coin?.symbol + 'tmn'), data?.coin?.decimal))}
            &nbsp; TMN
          </p>
        </div>
        {data?.radioBuy !== "defualt"
          &&
          <>
            <div className='dec'>
              <p>شبکه</p>
              <p className='ltr'>{data?.network?.network?.name}</p>
            </div>
            <div className='dec'>
              <p>آدرس کیف پول</p>
              <p className='ltr'>{data?.address}</p>
            </div>
            {data?.needTagMemo
              &&
              <div className='dec'>
                <p>ممو</p>
                <p className='ltr'>{data?.tagMemo}</p>
              </div>
            }
          </>
        }
        <div className='divider'></div>
        <div className='warningOrderFast'>
          <p>کاربر گرامی به دلیل نوسانات قیمت  مقدار دریافتی شما به صورت تقریبی محاسبه گردیده است</p>
        </div>
        <div className='checkBoxDialog'>
          <Checkbox id='checkBox' checked={checked} onChange={hideDialog} sx={{
            color: "var(--color-black5)",
          }} />
          <label htmlFor='checkBox'>این تاییدیه را در معاملات بعد نشان نده</label>
        </div>
        <div className='btns'>
          {myLoading
              ? <div className="loadingCircular h41"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <button className="finalCheckBtn" onClick={handelSend} > تایید  </button>
          }
          <button className="finalCheckBtn btnCancel2" onClick={() => close()} > لغو  </button>
        </div>
      </div>
    </DialogContent>
  )
}

export default ConfirmBuySellFast