import { CircularProgress, Skeleton, Stack } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const FetchListLoadSmall = ({ load, list }: { load: any, list: any }) => {
  return (
    <div className='fetchListLoad' style={{margin:"0px"}}>
      {load
        ? <div className='emptyLoad'>
          <Stack spacing={1} style={{ width: "100%" }}>
            {new Array(3)?.fill("a").map((i, n) =>
              <Skeleton key={n} variant="text" sx={{ fontSize: '0.9rem' }} />
            )}
          </Stack>
        </div>
        : _.isEmpty(list)
        && <div className='emptyLoad' style={{marginTop:"10px"}}>
          <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" />
          <p>موردی یافت نشد</p>
        </div>
      }
    </div>

  )
}

export default FetchListLoadSmall