import { Checkbox, CircularProgress, DialogContent } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import { autoFormatter, toFixed, zeroFormat } from '../../../helpers/tools';
import moment from 'jalali-moment';
import { sideFA, typeOrder2FA } from '../../../config/variable';
import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useDispatch } from 'react-redux';
import * as DialogShow  from '../../../services/redux/dialog';

const DialogPriceOrder = ({ buyOrSellAction, orderBookPrice, userPric, side, pair }: any) => {
   const dispatch = useDispatch<any>();
  const [checked, setChecked] = useState(false);
  const [confiemOrder, setConfiemOrder] = useLocalStorage('confiemPriceOrder', "false");

  const hideDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setConfiemOrder(event.target.checked + "")
  }

  return (
    <DialogContent className='detailWithdraw'>
      <div className='body'>
        <div className='dec'>
          <p >⚠️ قیمت پیشنهادی شما {side == "buy" ? "پایین‌تر" : "بالاتر"} از قیمت {side == "buy" ? "فروشندگان" : "خریداران"} است</p>
        </div>
        <div className='dec'>
          <p >در حال حاضر، هیچ {side == "buy" ? "فروشنده‌ای" : "خریداری"} با قیمت <span >{zeroFormat(userPric)} {pair?.toUpperCase()}</span> در دسترس نیست.</p>
        </div>
        <div className='dec'>
          <p >💡 <span >بهترین قیمت  {side == "buy" ? "فروش" : "خرید"} :</span> <span style={{ color: "#ff9800", fontWeight: "500", fontSize: 13 }}>{zeroFormat(orderBookPrice)} {pair?.toUpperCase()}</span></p>
        </div>
        <div className='dec'>
        <p >✅ برای انجام سریع سفارش، قیمت را به <span >{zeroFormat(orderBookPrice)} {pair?.toUpperCase()}</span> یا {side == "buy" ? "بالاتر" : "پایین‌تر "} تنظیم کنید. در غیر این صورت، سفارش شما باز می‌ماند تا زمانی که {side == "buy" ? "فروشنده‌ای" : "خریداری"} با قیمت پیشنهادی شما پیدا شود.</p>

        </div>

        <div className='divider'></div>
        <div className='checkBoxDialog'>
          <Checkbox id='checkBox' checked={checked} onChange={hideDialog} sx={{
            color: "var(--color-black5)",
          }} />
          <label htmlFor='checkBox'>این تاییدیه را در معاملات بعد نشان نده</label>
        </div>
        <div className='btns'>
          <button className="finalCheckBtn" onClick={() => {
            dispatch(DialogShow.hide());
            buyOrSellAction();
          }}  > تایید  </button>
          <button className="finalCheckBtn btnCancel2" onClick={() => dispatch(DialogShow.hide())} > لغو  </button>
        </div>
      </div>
    </DialogContent>
  )
}

export default DialogPriceOrder