import React from 'react';
import { NavLink } from 'react-router-dom';
import { menu } from '../../../config/menu';

const CartBtnsNav = () => {
  return (
    <div className='cartBtnsNav'>
      <NavLink to={menu.order.childs.orederPro.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_pro'></span>
          </div>
        </div>
        <p className='t1'>معامله حرفه ای</p>
      </NavLink>
      <div className='herDivider'></div>
      <NavLink to={menu.order.childs.fastOrder.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_fast'></span>
          </div>
        </div>
        <p className='t1'>معامله آسان</p>
      </NavLink>
      <div className='herDivider'></div>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCash.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_depo'></span>
          </div>
        </div>
        <p className='t1'>واریز</p>
      </NavLink>
      <div className='herDivider'></div>
      <NavLink to={menu.financial.childs.withdrawAll.childs.withdrawCash.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_withdraw'></span>
          </div>
        </div>
        <p className='t1'>برداشت</p>
      </NavLink>
      <div className='herDivider'></div>
      <NavLink to={menu.financial.childs.wallet.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_wallet'></span>
          </div>
        </div>
        <p className='t1'>کیف پول</p>
      </NavLink>
      {/* <div className='herDivider'></div>
      <NavLink to={menu.setting.childs.googleAuth.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_security'></span>
          </div>
        </div>
        <p className='t1'>امنیت</p>
      </NavLink> */}
      <div className='herDivider'></div>
      <NavLink to={menu.financial.childs.transactionHistory.childs.depositCash.url} className='item'>
        <div className='cir1'>
          <div className='cir2'>
            <span className='icon-d_order'></span>
          </div>
        </div>
        <p className='t1'>تاریخچه ها</p>
      </NavLink>
    </div>
  );
}

export default CartBtnsNav;
