import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../../services/redux/user';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import useSnack from '../../../hooks/useSnack';
import { CircularProgress } from '@mui/material';
import ConfirmUpgrade from '../dialogs/ConfirmUpgrade';
import * as DialogShow from '../../../services/redux/dialog';
import heic2any from 'heic2any';

const TopUpgrade = ({ setChangeview }: any) => {
  const inputFile: any = useRef(null);
  const [fileImage, setFileImage]: any = useState('');
  const [text, setText]: any = useState('');
  const [change, setChange]: any = useState(false);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);


  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0].type?.startsWith("image/")) {
      setFileImage(e.target.files[0])
    } else {
      setFileImage(null)
    }
  };

  // const handleImageUpload = async () => {
  //   if (!fileImage) {
  //     snackUse.showError("لطفا مدارک خود را بارگذاری کنید");
  //     return;
  //   }

  //   if (text?.trim()?.length < 2) {
  //     snackUse.showError("لطفا متن درخواست را وارد کنید");
  //     return;
  //   }
  //   const file:any = fileImage;

  //   if (file && file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.src = e.target?.result as string;

  //       img.onload = async () => {
  //         const canvas = document.createElement('canvas');
  //         const maxWidth = 800;
  //         const scaleFactor = maxWidth / img.width;
  //         const width = Math.min(maxWidth, img.width);
  //         const height = img.height * scaleFactor;

  //         canvas.width = width;
  //         canvas.height = height;

  //         const ctx = canvas.getContext('2d');
  //         ctx?.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           async (blob) => {
  //             if (blob) {
  //               // setFileImage(blob)
  //               sendImg(blob)
  //             }
  //           },'image/jpeg',  0.6 );
  //       };
  //     };
  //   }
  // };

  const handleImageUpload = async () => {
    if (!fileImage) {
      snackUse.showError("لطفا مدارک خود را بارگذاری کنید");
      return;
    }

    if (text?.trim()?.length < 2) {
      snackUse.showError("لطفا متن درخواست را وارد کنید");
      return;
    }
    const file:any = fileImage;

    if (!file || !file.type.startsWith('image/')) return;

    let convertedFile = file;

    // **تبدیل HEIC به JPEG**
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.8 });
        convertedFile = new File([blob as Blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(convertedFile);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 1024;  // **حداکثر عرض تصویر**
        const maxHeight = 1024; // **حداکثر ارتفاع تصویر**
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = Math.floor(width * scaleFactor);
          height = Math.floor(height * scaleFactor);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // **فشرده‌سازی و ارسال**
        let quality = 0.8; // کیفیت اولیه
        let blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', quality)
        );

        // **کاهش حجم تا زیر 600 کیلوبایت**
        while (blob && blob.size > 600 * 1024 && quality > 0.2) {
          quality -= 0.1;
          blob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(resolve, 'image/jpeg', quality)
          );
        }

        if (blob) {
          sendImg(blob)
        }
      };
    };
  };

  const sendImg = async (myFile:any) => {
    setMyLoading(true)

    const form_data = new FormData();
    form_data.append("type", "signature");
    form_data.append("doc", myFile);

    const result = await gather(`${config.api}/v1/file`).upload(form_data);

    if (result.code === 200) {
      sendUpgrade(result?.data?.link)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setMyLoading(false)
    }

  }

  const sendUpgrade = async (link: any) => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/sign`).put({ text: text, signature: link });

    if (result.code === 200) {
      await gather(`${config.api}/v1/user/levelUp`).get();
      await dispatch(getProfile());
      dispatch(DialogShow.show(<ConfirmUpgrade text={"درخواست شما جهت بررسی ارسال شد"} close={() => dispatch(DialogShow.hide())} />))
      setChange(true);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const handleCheckUpgrade = () => {
    if (change) {
      return (<div className='checkUpgradePage'>
        <img src="/icons/m-IconCheckUpgrade.svg" alt="" />
        <p className='colorRed--x'>درخواست شما جهت بررسی ارسال شد</p>
        <button className='finalCheckBtn' onClick={() => setChangeview("showAll")}>بازگشت به صفحه اصلی</button>
      </div>)
    } else {
      return (<div className='topUpgradePage'>
        <p className='force'>متن خود را بنویسید</p>
        <div className='textareaBox'>
          <textarea name="" id="" value={text} onChange={(e) => setText(e.target.value)} placeholder='متن'></textarea>
        </div>
        <p>آپلود مدارک</p>
        <div className='fileUploadBox'>
          <input type="file" accept="image/*" className='none' ref={inputFile} onChange={handleFileChange} placeholder='.' />
          <span className='iconDocUpload_2'></span>
          <h4 onClick={() => inputFile.current.click()}>آپلود کنید</h4>
          {fileImage?.name
            ? <p>{fileImage?.name}</p>
            : <p>پسوندهای مجاز: JPG - PNG - JPEG</p>
          }
        </div>
        {
          myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button className='finalCheckBtn' onClick={handleImageUpload}>ارسال درخواست</button>
        }
      </div>)
    }
  }

  return (
    <>{handleCheckUpgrade()}</>
  )
}

export default TopUpgrade