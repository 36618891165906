import { DialogContent, Divider } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { sideFA, statusFA, typePaymentFA } from '../../../config/variable'
import moment from 'jalali-moment'
import { zeroFormat } from '../../../helpers/tools'
import { IoMdCloseCircle } from 'react-icons/io'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useSnack from '../../../hooks/useSnack'

const DetailPayment = ({ data, deposit, close }: any) => {
  const snackUse = useSnack();

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw'>
        <div className='body'>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>ثبت درخواست</h4>
              <p>{data?.createdAt && moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>پردازش سیستم</h4>
              <p>{data?.createdAt && moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' className='line' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step'>
            <div className={`circlePoint ${data?.status}`}>
              <span></span>
            </div>
            <div className={`textDate ${data?.status}`}>
              <h4 id={`${data?.status}`}>{statusFA[data?.status]}</h4>
              <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
          </div>
          <Divider className='divder' />
          <div className='dec'>
            <p>شناسه درخواست</p>
            <div className='shaba'>
              <CopyToClipboard text={data?.uniqueId} onCopy={() => { data?.uniqueId && snackUse.showSaccess("شناسه درخواست کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?.uniqueId}</p>
            </div>
          </div>
          {deposit
            ? <div className='dec'>
              <p>کد پیگیری درگاه</p>
              {data?.reference
                ? <div className='shaba'>
                  <CopyToClipboard text={data?.reference} onCopy={() => { data?.reference && snackUse.showSaccess("کد پیگیری کپی شد") }}>
                    <span className='icon-IconCopy3 cPointer'></span>
                  </CopyToClipboard>
                  <p>{data?.reference ?? "---"}</p>
                </div>
                : "---"}
            </div>
            : <div className='dec'>
              <p>کد پیگیری بانکی</p>
              {data?.hash
                ?
                <div className='shaba'>
                  <CopyToClipboard text={data?.hash} onCopy={() => { data?.hash && snackUse.showSaccess("کد پیگیری کپی شد") }}>
                    <span className='icon-IconCopy3 cPointer'></span>
                  </CopyToClipboard>
                  <p className='ltr'>{data?.hash?.length > 18 ? data?.hash?.slice(0, 6) + ". . ." + data?.hash?.slice(-6, data?.hash?.length) : data?.hash}</p>
                </div>
                : "---"
              }
            </div>
          }
          <div className='dec'>
            <p>وضعیت</p>
            <div className='status confirm'>
              {data?.status == "reject"
                ? <IoMdCloseCircle className='close' size={22} />
                : data?.status == "confirm" ? <span className='icon-TickSquare'></span> : <span className='icon-IconInfoCircle'></span>
                // : <LazyLoadImage crossOrigin='anonymous' src={`/icons/${data?.status == "confirm" ? "m-IconTickSquare" : "m-IconInfoCircle"}.svg`} alt="" />
              }
              <p id={`${data?.status}`}>{statusFA[data?.status]}</p>
            </div>
          </div>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p>کیف پول {deposit ? "واریز" : "برداشت"} </p>
            <p>کیف پول اسپات</p>
          </div>
          <div className='dec'>
            <p>سمت</p>
            <p className={`color-${data?.side}`}>{sideFA[data?.side]}</p>
          </div>
          <div className='dec'>
            <p>روش پرداخت</p>
            <p className={``}>{typePaymentFA[data?.type]}</p>
          </div>
          <div className='dec'>
            <p>نوع ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/iranflag.svg" alt="" />
              <p className='ltr'>تومان</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار</p>
            <p className='ltr'>{zeroFormat(data?.amount)}&nbsp; تومان</p>
          </div>
          <div className='dec'>
            <p>کارمزد</p>
            <p className='ltr'>{zeroFormat(data?.fee)}&nbsp; تومان </p>
          </div>
          <div className='dec'>
            <p>مقدار نهایی</p>
            <p className='ltr'>{zeroFormat(data?.final)}&nbsp; تومان</p>
          </div>
          <div className='dec'>
            <p>{deposit ? "شماره کارت" : "شماره شبا"}</p>
            <p className='ltr'>{deposit ? data?.card?.number?.match(new RegExp('.{1,4}', 'g')).join("-") : data?.card?.iban}</p>
          </div>
          {(data?.side == "cashout" && data?.settleAt) &&
            <div className='dec'>
              <p>تخمین زمان واریز</p>
              <p>{data?.settleAt ? moment(Number(data?.settleAt)+2700000).locale("fa").format('HH:mm  YYYY-MM-DD') : "---"}</p>
            </div>}
        </div>
      </DialogContent>
    </>
  )
}

export default DetailPayment