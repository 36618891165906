import { Divider } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/CustomInput";
import { autoFormatter, formatter, toCeil, toFixed, zeroFormat } from "../../helpers/tools";
import CustomSelectBoxLabel from "../../components/CustomSelectBoxLabel";
import { detect_bank } from "../../helpers/bankName";
import _ from "lodash";
import NavChangeWithdraw from "./items/NavChangeWithdraw";
import { NavLink } from "react-router-dom";
import { menu } from "../../config/menu";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import History from '../depositAll/items/History';
import ConfirmCash from "./dialogs/ConfirmCash";
import * as DialogShow from "../../services/redux/dialog";
import useSnack from "../../hooks/useSnack";
import Footer from "../../components/Footer";
import NeedUpgrade from "../../components/NeedUpgrade";

const WithdrawCash = () => {
  const dispatch = useDispatch<any>();
  const cardBanck = useSelector((state: any) => state.user.cards);
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const profile: any = useSelector((state: any) => state.user?.profile);
  const setting = useSelector((state: any) => state.publicApis?.settingApi);
  const snackUse = useSnack();
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState(false);
  const [card, setCard]: any = useState(Object.values(cardBanck)[0] ?? {});
  const [reLoad, setReLoad]: any = useState('');
  const [cardError, setCardError]: any = useState(false);
  const Remainder = profile.plane.level.withdraw.fiat.daily - profile.limit.fiat.daily;

  const handlePercent = (count: any, total: any) => {
    if (count && total) {
      return toFixed(Number(Number(count * 100) / total), 1)
    }
    return 0
  }

  const handleMaxWithdraw = () => {
    const daily = Number(profile?.limit?.fiat?.daily);
    const monthly = Number(profile?.limit?.fiat?.monthly);
    const maxMonthly = Number(profile?.plane?.level?.withdraw?.fiat?.monthly);
    const maxDaily = Number(profile?.plane?.level?.withdraw?.fiat?.daily);

    const calcDaily = Number(maxDaily) - Number(daily) || 0;
    const calcMonthly = Number(maxMonthly) - Number(monthly) || 0;

    return { calcDaily, calcMonthly }
  }

  const send = async () => {
    if (profile?.plane?.level?.index === 0 || _.isNull(profile?.plane?.level?.index)) {
      dispatch(DialogShow.show2(<NeedUpgrade />))
      return
    }

    if (_.isEmpty(card)) {
      snackUse.showError("لطفا کارت بانکی خود را انتخاب کنید");
      setCardError(true);
      setTimeout(() => { setCardError(false) }, 3000)
      return;
    }

    if (amount ==0) {
      snackUse.showError(`لطفا مبلغ را وارد کنید`);
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    if (amount < setting?.fiat?.withdraw?.min) {
      snackUse.showError(`حداقل مبلغ برداشت ${zeroFormat(Number(setting?.fiat?.withdraw?.min))} تومان است`);
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    if (amount > handleMaxWithdraw().calcDaily || amount > handleMaxWithdraw().calcMonthly) {
      snackUse.showError(`مبلغ درخواست شده بیش از سقف مجاز است`);
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    const data: any = {
      amount: Number(amount),
      card: card,
      final: Number(amount - (toCeil(amount / (setting?.fiat?.withdraw?.step ?? 1), 0) * (setting?.fiat?.withdraw?.fee ?? 0))) > 0 ? zeroFormat(Number(amount - (toCeil(amount / (setting?.fiat?.withdraw?.step ?? 1), 0) * (setting?.fiat?.withdraw?.fee ?? 0))), 4, false) : 0,
      fee: autoFormatter(toCeil(amount / (setting?.fiat?.withdraw?.step ?? 1), 0) * (setting?.fiat?.withdraw?.fee ?? 0))
    }

    dispatch(DialogShow.show(<ConfirmCash data={data}
      setAmount={setAmount}
      setReLoad={setReLoad}
      close={() => dispatch(DialogShow.hide())} />))
  }


  useEffect(() => {
    document.title = menu.financial.childs.withdrawAll.childs.withdrawCash.title;
  }, []);


  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox'>
                <NavChangeWithdraw />
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>1</span></p>
                    <h5>انتخاب شماره کارت</h5>
                  </div>
                  <NavLink className="addCard" to={menu.userBank.childs.account.url}>
                    <span className='icon-IconAddCard'></span>
                    <p>افزودن کارت</p>
                  </NavLink>
                </div>
                <CustomSelectBoxLabel element={
                  <div className='showBanck'>
                    {card?.number
                      ? <><h4 className='p-0 fontCard' >{card?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</h4>
                        <img className='m-0' src={`${!_.isEmpty(detect_bank(card?.number).bank_logo) ? detect_bank(card?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
                      </>
                      : <h4 className='defaultText'><b>شماره کارت خود را انتخاب کنید</b></h4>}
                  </div>}
                  error={cardError} listData={Object.values(cardBanck) ?? []} label={"شماره کارت"} setInputSatet={setCard} className={" styleBankSelect mb-28 mt-10"} />
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>2</span></p>
                    <h5>مقدار برداشت</h5>
                  </div>
                  <div className="addCard" onClick={() => setAmount(Number(mainWalletObj["tmn"]?.free))}>
                    <p>حداکثر قابل برداشت <b>{formatter(mainWalletObj["tmn"]?.free, 0, 0, true)}</b> تومان</p>
                  </div>
                </div>
                <CustomInput error={amountError}decimalScale={0} inputState={amount} setInputSatet={setAmount} label={""} classStart={""} startEle={<p className='colorGray '>تومان</p>} endEle={""} className={"ltr mb-17"} placeholder={"0.00"} />
                <Divider className='divider' />
                <div className='feeFinalAmount'>
                  <div>
                    <p>کارمزد نهایی</p>
                    {/* <span className='icon-IconInfoCircle'></span> */}
                  </div>
                  <p> {autoFormatter(toCeil(amount / (setting?.fiat?.withdraw?.step ?? 1), 0) * (setting?.fiat?.withdraw?.fee ?? 0))} <b>تومان</b></p>
                </div>
                <div className='feeFinalAmount'>
                  <p>حداقل مقدار برداشت</p>
                  <p> {zeroFormat(Number(setting?.fiat?.withdraw?.min))} <b>تومان</b></p>
                </div>
                <div className='feeFinalAmount'>
                  <p> مقدار نهایی</p>
                  <p> {Number(amount - (toCeil(amount / (setting?.fiat?.withdraw?.step ?? 1), 0) * (setting?.fiat?.withdraw?.fee ?? 0))) > 0 ? zeroFormat(Number(amount - (toCeil(amount / (setting?.fiat?.withdraw?.step ?? 1), 0) * (setting?.fiat?.withdraw?.fee ?? 0))), 4, false) : 0} <b>تومان</b></p>
                </div>
                <button className='submit' onClick={send}>درخواست برداشت</button>
                <div className='limitWithdraw'>
                  <div className='dailyText'>
                    <p className='dotGreen'>سقف برداشت شما (روزانه):</p>
                    <h5>{zeroFormat(Number(profile?.plane?.level?.withdraw?.fiat?.daily))} تومان</h5>
                  </div>
                  <div className='dailyText'>
                    <p>برداشت امروز :</p>
                    <h5>{zeroFormat(toFixed(Number(profile?.limit?.fiat?.daily),0),0,false)} تومان</h5>
                  </div>
                  <div className='remainingDailyText'>
                    <div className="text">
                      <h5 className="fontYekanBakh">{handlePercent(profile?.limit?.fiat?.daily, profile?.plane?.level?.withdraw?.fiat?.daily)}٪ سقف روزانه استفاده شده</h5>
                      <div className="boxDetailMonthly">
                        <span className='icon-IconInfoCircle'></span>
                        <div className="monthly">
                          <div className='dailyText mb-15'>
                            <p className='dotGreen'>سقف برداشت شما (ماهانه):</p>
                            <h5>{zeroFormat(Number(profile?.plane?.level?.withdraw?.fiat?.monthly))} تومان</h5>
                          </div>
                          <div className='dailyText'>
                            <p>برداشت ماهانه :</p>
                            <h5>{zeroFormat(toFixed(Number(profile?.limit?.fiat?.monthly),0),0,false)} تومان</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='allGreen'>
                      <div className='useRed' style={{ width: `${handlePercent(profile?.limit?.fiat?.daily, profile?.plane?.level?.withdraw?.fiat?.daily)}%` }}></div>
                    </div>
                    <div className='remainderMaxWithdraw'>
                      <span className='t1'>باقی مانده سقف روزانه :</span>
                      <span className='t2'>{autoFormatter(Remainder)} تومان</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='detailBox'>
                <img crossOrigin='anonymous' className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                {/* <div className='minDepoText'>
            <img src="/icons/m-IconInfoCircleGreen.svg" alt="" />
            <p>حداقل میزان برداشت 20,000 تومان می‌باشد.</p>
          </div> */}
                <div className='infoText'>
                  <h5><b>نکات</b> مهم</h5>
                  <p>هنگام انتخاب <b>کارت بانکی</b> دقت کنید.</p>
                  <p>مقدار مبلغ درخواستی <b>به تومان</b> وارد نمایید.</p>
                  <p>جهت دریافت سریعتر تومان در خارج از سیکل پایا ، از بانک های <b className="colorRed"> صادرات </b> استفاده نمائید  </p>
                  <p>سیکل واریزی های پایا در ساعات <b> 4:30 ، 11:30 ، 14:30 و 19:30 </b> توسط شاپرک انجام خواهد شد  </p>
                  <p> هنگام برداشت تومانی از حساب کاربری ، به مانده سقف روزانه/ ماهانه خود دقت کنید و در صورت نیاز به سقف بالاتر ، از منوی ارتقا حساب کاربری اقدام نمایید.</p>
                </div>
              </div>
            </div>
            <History flag={false} reload={reLoad} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default WithdrawCash