import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};


const CustomSelectBox = ({ text, rang, sum, selecting, error }: { text: string, rang: number, sum: number, selecting: any, error: boolean }) => {
  const [personName, setPersonName] = React.useState<string[]>([]);
  const arr = new Array(rang).fill("SelectBox");

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const { target: { value }, } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value,);

    selecting(value);
  };

  return (
    <FormControl className='customSelectBox' >
      <InputLabel id="delectBox-label">{text}</InputLabel>
      <Select className={!error ? "select" : "error"}
        labelId="delectBox-label"
        id="delectBox"
        value={personName}
        onChange={handleChange}
        label={text}
        MenuProps={MenuProps}
      >
        {arr?.map((i: any, n: any) => (
          <MenuItem className='menuItemSelect' key={n} value={`${n + sum}`.length < 2 ? `0${n + sum}` : `${n + sum}`}>{`${n + sum}`.length < 2 ? `0${n + sum}` : `${n + sum}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomSelectBox