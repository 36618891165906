import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import gather from '../../helpers/gather';
import { config } from '../../config/config';

const initialState = {
  balance: {
    asset: {} as { [k: string]: any },
    fiat: {} as any,
  },
  profile: {} as any,
  favorite: [] as string[],
  cards: {} as { [k: string]: any },
  mainWalletObj: {} as any,
  hideShowAsset: false,
};

const getCards = createAsyncThunk('list/fetchList', async () => {
  return await gather(`${config.api}/v1/card`).get();
});

const getAccountBalance = createAsyncThunk('accountBalance/fetchAccountBalance', async () => {
  return await gather(`${config.api}/v1/balance`).get();
});

const getProfile = createAsyncThunk('profile/fetchProfile', async () => {
  return await gather(`${config.api}/v1/user`).get();
});

const getFavorite = createAsyncThunk('favorite/fetchProfile', async () => {
  return await gather(`${config.api}/v1/favorite`).get();
});

const getWalletBalance = createAsyncThunk('walletBalance/fetchWalletBalance', async () => {
  return await gather(`${config.api}/v1/asset`).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateBalance: (state, action) => {
      state.balance.fiat = action.payload;
      state.mainWalletObj['tmn'] = action?.payload
    },
    updateAsset: (state, action) => {
      state.balance.asset[action.payload.coin] = action.payload;
      state.mainWalletObj[action?.payload?.coin] = action?.payload
    },
    updateProfile: (state, action) => {
      if (action.payload.nationalCode) {
        state.profile.nationalCode = action.payload.nationalCode;
      }
      if (action.payload.birthDate) {
        state.profile.birthDate = action.payload.birthDate;
      }
    },
    removeCard: (state, actions) => {
      delete state.cards[actions.payload];

      gather(`${config.api}/v1/card`).delete({
        card: actions.payload,
      });
    },
    addRemoveFavorite: (state, actions) => {
      gather(`${config.api}/v1/favorite`)[state.favorite.includes(actions.payload) ? 'delete' : 'post']({
        coin: actions.payload,
      });
      if (state.favorite.includes(actions.payload)) {
        state.favorite = state.favorite.filter(e => e != actions.payload)
      } else {
        state.favorite.push(actions.payload);
      }
    },
    hideShowAssetApi: (state, actions) => {
      gather(`${config.api}/v1/user/setting`).post(actions.payload);

      state.hideShowAsset = actions.payload?.hideAsset

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCards.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data?.filter((ele:any) => ele?.isActive).forEach((value: any) => {
            state.cards[value.number] = value;
          });
        }
      })
      .addCase(getWalletBalance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload.data.forEach((value: any) => {
            state.balance.asset[value.coin.symbol] = value;
            state.mainWalletObj[value.coin?.symbol] = value;
          });
        }
      })
      .addCase(getAccountBalance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.balance.fiat = action.payload.data;
          state.mainWalletObj["tmn"] = action.payload?.data
        }
      })
      .addCase(getFavorite.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.favorite = action.payload.data;
        }
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.profile = action.payload.data;
          state.hideShowAsset = action.payload.data?.setting?.hideAsset ?? false
        }
      });
  },
});

export { getAccountBalance, getCards, getFavorite, getProfile, getWalletBalance };
export const { updateAsset, updateBalance, updateProfile, addRemoveFavorite, hideShowAssetApi } = slice.actions;
export default slice.reducer;
