import React, { useEffect } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Header from '../../components/header/Header';
import Footer from '../../components/Footer';
import "react-circular-progressbar/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CartDetail from './items/CartDetail';
import CartWallet from './items/CartWallet';
import NewsBox from './items/NewsBox';
import CardChartChange from './items/CardChartChange';
import TableTraNotBox from './items/TableTra_NotBox';
import { useSelector } from 'react-redux';
import CardInvite from './items/CardInvite';
import { NavLink } from 'react-router-dom';
import { menu } from '../../config/menu';
import CartBtnsNav from './items/CartBtnsNav';

const Dashboard = () => {
  const profile = useSelector((state: any) => state.user.profile);

    useEffect(() => {
      document.title = menu.dashboard.title;
    }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='dashboard'>
            <CartDetail profile={profile} />
            <CartBtnsNav />
            <CartWallet profile={profile} />
            <NewsBox />
            <div className='cardReadMore'>
              <div className='right'>
                <span className='icon-quoteSquare'></span>
                <p className='t1'>آپدیت جدید سامانه اکسنوین اعمال شد</p>
              </div>
              <div className='left'>
                <p className='t1'>بیشتر بخوانید</p>
                <span className='icon-arrowCircle'></span>
              </div>
            </div>
            <CardChartChange />
            <TableTraNotBox />
            <CardInvite />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
