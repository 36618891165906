import React, { useEffect, useState } from 'react';
import { toFarsiNumber, zeroFormat } from '../../../helpers/tools';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { NavLink } from 'react-router-dom';
import { menu } from '../../../config/menu';
import { useSelector } from 'react-redux';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import moment from 'moment';
import useTicker from '../../../hooks/useTicker';

const CartDetail = ({ profile }: { profile: any }) => {
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [rateAuth, setRateAuth] = useState(0);
  const [listOrder, setListOrder]: any = useState(null);
  // const ticker = useTicker();
  // const [goToAuth, setgoToAuth] = useState(menu.upgradeLevel.url);

  const handleAuth = () => {
    let userRate = 0
    const listObj = ["card", "document", "email", "identity", "rules"];
    listObj?.forEach(element => {

      if (profile?.verify[element]) {
        userRate += 20
      } else {
        // if (element == "card") setgoToAuth(menu.userBank.childs.account.url)
        // if (element == "document") setgoToAuth(menu.auths.childs.uploadDoc.url)
        // if (element == "email") setgoToAuth(menu.setting.childs.accountUser.url)
        // if (element == "identity") setgoToAuth(menu.upgradeLevel.url)
        // if (element == "rules") setgoToAuth(menu.upgradeLevel.url)

      }
    });
    setRateAuth(userRate)
  }

  const get = async () => {
    setListOrder(null)

    const result = await gather(`${config.api}/v1/order?sortBy=createdAt&orderBy=desc&limit=2000&fromDate=${Math.floor(moment().subtract(30, 'd').valueOf())}&toDate=${Math.floor(moment().add(1, 'm').valueOf())}`).get()

    if (result.message === 200) {
      setListOrder(result?.data ?? []);

    } else {
      setListOrder(null)
    }
  }

  useEffect(() => {
    if (userIsLogin) {
      handleAuth();
      get();
    }
  }, []);

  return (
    <div className='cartDetail'>
      <div className='boxAuth'>
        <div className='authChart'>
          <CircularProgressbar
            value={rateAuth}
            text={`${toFarsiNumber(rateAuth)}%`}
            strokeWidth={13}
            styles={buildStyles({
              textSize: "20px",
              pathColor: "#22c58b",
              textColor: "var(--txBody-color)",
              trailColor: "#d6d6d6",
            })}
          />
        </div>
        <div className='boxText'>
          <p className='t1'>احراز هویت</p>
          <p className='t2'>احراز هویت شما تکمیل {rateAuth !== 100 ? "نشده" : "شده"} است.</p>
          <div className='boxBtn'>
            <div className='txts'>
              <p className='p1'>درصد تکمیل</p>
              <p className='p2'>درصد باقی مانده</p>
            </div>
            <div className='startOrder'>
              <NavLink to={menu.order.childs.fastOrder.url} className='btn2'>شروع معامله آسان</NavLink>
              <NavLink to={menu.order.childs.orederPro.url} className='btn'>شروع معامله حرفه ای</NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className='divider'></div>
      <div className='boxDetail'>
        <div className='right'>
          <div className='userIcon'>
            <img src="/images/m-IconUser1.png" alt="userIcon" />
          </div>
          <div className='userName'>
            <p className='t1'>{(profile?.name ?? "") + " " + (profile?.family ?? "")}</p>
            <p className='t2'>{toFarsiNumber(profile?.mobile ?? "")}</p>
          </div>
        </div>
        <div className='left'>
          <div className='item'>
            <p className='t1'>سطح کاربری</p>
            <p className='t2'>سطح {profile?.plane?.level?.name ?? "---"}</p>
          </div>
          <div className='item'>
            <p className='t1'> کارمزد خرید</p>
            <p className='t2'>{toFarsiNumber(profile?.plane?.level?.fee?.tmn?.buy ?? 0)}%</p>
          </div>
          <div className='item'>
            <p className='t1'> کارمزد فروش</p>
            <p className='t2'>{toFarsiNumber(profile?.plane?.level?.fee?.tmn?.sell ?? 0)}%</p>
          </div>
        </div>
      </div>
      <div className='boxSumOrders'>
        {/* <span className="icon-d_pro"></span> */}
        <p className='t1'>حجم معاملات ۳۰ روز اخیر شما
          &nbsp;<b>{listOrder ? toFarsiNumber(
            zeroFormat(listOrder
              ?.filter((ele:any)=>ele?.pair=="tmn")
              ?.reduce((n: any, e: any) => n + (Number(e.fill) * Number(e.price)), 0), 0, false)
          ) : "0"}</b>&nbsp;
          تومان</p>
      </div>
    </div>
  );
}

export default CartDetail;
