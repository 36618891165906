import { CircularProgress } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { menu } from '../../../config/menu';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CustomSelectBoxLabel from '../../../components/CustomSelectBoxLabel';
import CustomInputText from '../../../components/CustomInputText';
import useSnack from '../../../hooks/useSnack';
import heic2any from 'heic2any';

const CreateTicket = () => {
  const navigate = useNavigate()
  const inputFile: any = useRef(null);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [fileImage, setFileImage]: any = useState('');
  const [title, setTitle] = useState('');
  const [part, setPart]: any = useState({ name: "بخش پشتیبانی" });
  const [errorPart, setErrorPart]: any = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState(false);
  const [myLoading, setMyLoading] = useState(false);

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0].type?.startsWith("image/")) {
      setFileImage(e.target.files[0])
    } else {
      setFileImage(null)
    }
  };

  const handleTitle = (e: any) => {
    const newTilte = e.replace(/\[A-Z]/g, "").slice(0, 40)
    setTitle(newTilte)
  }

  // const handleImageUpload = async () => {
  //   if (title.trim().length < 3) {
  //     snackUse.showError("لطفا عنوان تیکت را وارد کنید");
  //     setErrorTitle(true);
  //     setTimeout(() => { setErrorTitle(false) }, 3000);
  //     return;
  //   }
  //   if (message.trim().length < 3) {
  //     snackUse.showError("لطفا متن پیام را وارد کنید");
  //     setErrorMessage(true);
  //     setTimeout(() => { setErrorMessage(false) }, 3000);
  //     return;
  //   }

  //   const file:any = fileImage;

  //   if (file && file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.src = e.target?.result as string;

  //       img.onload = async () => {
  //         const canvas = document.createElement('canvas');
  //         const maxWidth = 800;
  //         const scaleFactor = maxWidth / img.width;
  //         const width = Math.min(maxWidth, img.width);
  //         const height = img.height * scaleFactor;

  //         canvas.width = width;
  //         canvas.height = height;

  //         const ctx = canvas.getContext('2d');
  //         ctx?.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           async (blob) => {
  //             if (blob) {
  //               // setFileImage(blob)
  //               sendImg(blob)
  //             }
  //           },'image/jpeg',  0.6 );
  //       };
  //     };
  //   }
  // };

  const handleImageUpload = async () => {
    if (title.trim().length < 3) {
      snackUse.showError("لطفا عنوان تیکت را وارد کنید");
      setErrorTitle(true);
      setTimeout(() => { setErrorTitle(false) }, 3000);
      return;
    }
    if (message.trim().length < 3) {
      snackUse.showError("لطفا متن پیام را وارد کنید");
      setErrorMessage(true);
      setTimeout(() => { setErrorMessage(false) }, 3000);
      return;
    }

    const file:any = fileImage;

    if (!file || !file.type.startsWith('image/')) return;

    let convertedFile = file;

    // **تبدیل HEIC به JPEG**
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.8 });
        convertedFile = new File([blob as Blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(convertedFile);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 1024;  // **حداکثر عرض تصویر**
        const maxHeight = 1024; // **حداکثر ارتفاع تصویر**
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = Math.floor(width * scaleFactor);
          height = Math.floor(height * scaleFactor);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // **فشرده‌سازی و ارسال**
        let quality = 0.8; // کیفیت اولیه
        let blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', quality)
        );

        // **کاهش حجم تا زیر 600 کیلوبایت**
        while (blob && blob.size > 600 * 1024 && quality > 0.2) {
          quality -= 0.1;
          blob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(resolve, 'image/jpeg', quality)
          );
        }

        if (blob) {
          sendImg(blob)
        }
      };
    };
  };

  const sendImg = async (myFile:any) => {

    setMyLoading(true)

    const form_data = new FormData();
    form_data.append("type", "ticket");
    form_data.append("doc", myFile);

    const result = await gather(`${config.api}/v1/file`).upload(form_data);

    if (result.code === 200) {
      // snackUse.showSaccess("ارسال مدارک با موفقیت انجام شد");
      send(result?.data?.link)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false)
  }

  const send = async (link: any) => {
    if (title.trim().length < 3) {
      snackUse.showError("لطفا عنوان تیکت را وارد کنید");
      setErrorTitle(true);
      setTimeout(() => { setErrorTitle(false) }, 3000);
      return;
    }
    if (message.trim().length < 3) {
      snackUse.showError("لطفا متن پیام را وارد کنید");
      setErrorMessage(true);
      setTimeout(() => { setErrorMessage(false) }, 3000);
      return;
    }

    setMyLoading(true)

    const body: any = {
      title: title,
      text: message,
      subject: "support",
    }
    if (link) body.file = link;

    const result = await gather(`${config.api}/v1/ticket`).post(body);

    if (result.message === 200) {
      snackUse.showSaccess("در خواست شما با موفقیت ثبت شد");
      setTitle("");
      setMessage("")
      navigate(menu.ticket.childs.history.url)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false)
  }

  return (
    <div className='createTicket'>
      <div className='headTitle'>
        <img crossOrigin='anonymous' src="/icons/CommentsWhite.svg" alt="" />
        <p>ثبت تیکت جدید</p>
      </div>
      <div className='body'>
        <div className='typeTicket'>
          <CustomInputText label={" عنوان تیکت *"} inputState={title} setInputSatet={handleTitle} className={"py-12"}
            error={errorTitle} classStart={"brNone"}
            startEle={<span className='icon-userprofile '></span>} />
          <CustomSelectBoxLabel flag={false} element={
            <div className='showBanck'>
              <h4 className='p-0 letSpacing0' >{_.isEmpty(part) ? "انتخاب کنید" : part?.name}</h4>
            </div>}
            error={errorPart} listData={[{ name: "بخش پشتیبانی" }]} label={"بخش *"} setInputSatet={setPart} className={"w50 styleBankSelect "} />
        </div>
        <div className='textareaStyle'>
          <div className="textRight">
            <span className="oninput">پیام *</span>
          </div>
          <div className={`inputGroup textarea ${errorMessage ? "brRed" : ""}`}>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)}
              placeholder="متن پیام خود را اینجا وارد کنید "
              rows={3}></textarea>
          </div>
        </div>
        <div className='bottom'>
          <div className='right'>
            <div className='selectFile' onClick={() => inputFile.current.click()}>
              <input type="file" accept="image/*" ref={inputFile} onChange={handleFileChange} placeholder='.' />
              <div>
                <span className='icon-Attachment'></span>
                <p>انتخاب فایل</p>
              </div>
            </div>
            {fileImage && <div className='nameFile'>
              <h5>{fileImage?.name}</h5>
              <span className='iconClose' onClick={() => setFileImage('')}></span>
            </div>}
          </div>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
              :
              <button className="finalCheckBtn" onClick={() => fileImage ? handleImageUpload() : send('')} >ارسال پیام</button>
          }
        </div>
      </div>

    </div>
  )
}

export default CreateTicket