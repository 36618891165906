import moment from 'jalali-moment';
import _ from 'lodash';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as BackdropShow from '../../../services/redux/backdrop';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import * as DialogShow from '../../../services/redux/dialog';
import { FcImageFile } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { menu } from '../../../config/menu';
import { CircularProgress } from '@mui/material';
import TimeLHistChat from './TimeLHistChat';
import useSnack from '../../../hooks/useSnack';
import { TextWithLineBreaks } from '../../../helpers/tools';
import AddImgText from '../dialog/AddImgText';
import heic2any from 'heic2any';
let fixValue: any = null;


const Chat = () => {
  const dispatch = useDispatch<any>();
  const data = useSelector((state: any) => state.dataSaver?.dataTicket);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const refBox: any = useRef(null);
  const inputFile: any = useRef(null);
  const [fileImage, setFileImage]: any = useState();
  const [message, setMessage]: any = useState('');
  const [listChat, setListChat]: any = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading]: any = useState(false);
  const [firtChat, setFirtChat]: any = useState(true);

  const scroolDown = () => {
    refBox.current?.scrollTo({ top: 1000000, behavior: 'smooth' });
  }

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0].type?.startsWith("image/")) {
      setFileImage(e.target.files[0]);
      handleImageUpload(e.target.files[0], message?.trim().length > 0 ? message : "فایل ضمیمه شد")
    } else {
      setFileImage(null)
    }
    inputFile.current.value = ""
  };

  const addTextList = (text: any, img: boolean,textImg:string) => {
    fixValue = null
    setMessage('');
    let obj: any = { type: "user", createdAt: new Date().getTime() };
    if (img) {
      obj.file = text;
      obj.text = textImg;
    } else {
      obj.text = text;
    }
    if (firtChat) {
      obj.newChat = true;
    }

    setListChat((chatList: any) => [...chatList, obj]);
    setFirtChat(false)
  }

  const send = async (text: any, img: boolean,textImg:string) => {
    if (text.trim().length == 0) return
    setLoading(true)
    setTimeout(() => scroolDown(), 200);

    const body = img
      ? { "file": text.trim() , "text": textImg }
      : { "text": text.trim() }
    const result = await gather(`${config.api}/v1/ticket/${data?._id}/reply`).post(body);

    if (result.message === 200) {
      addTextList(text, img,textImg);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setLoading(false)
  }

  // const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>,txt:any) => {
  //   // const file = event.target.files?.[0];
  //   const file:any = event;

  //   if (file && file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.src = e.target?.result as string;

  //       img.onload = async () => {
  //         const canvas = document.createElement('canvas');
  //         const maxWidth = 800;
  //         const scaleFactor = maxWidth / img.width;
  //         const width = Math.min(maxWidth, img.width);
  //         const height = img.height * scaleFactor;

  //         canvas.width = width;
  //         canvas.height = height;

  //         const ctx = canvas.getContext('2d');
  //         ctx?.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           async (blob) => {
  //             if (blob) {
  //               // setFileImage(blob)
  //               sendImg(blob,txt)
  //             }
  //           },'image/jpeg',  0.6 );
  //       };
  //     };
  //   }
  // };

  const handleImageUpload = async (event: any,txt:any) => {
    const file:any = event;

    if (!file || !file.type.startsWith('image/')) return;

    let convertedFile = file;

    // **تبدیل HEIC به JPEG**
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.8 });
        convertedFile = new File([blob as Blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(convertedFile);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 1024;  // **حداکثر عرض تصویر**
        const maxHeight = 1024; // **حداکثر ارتفاع تصویر**
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = Math.floor(width * scaleFactor);
          height = Math.floor(height * scaleFactor);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // **فشرده‌سازی و ارسال**
        let quality = 0.8; // کیفیت اولیه
        let blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', quality)
        );

        // **کاهش حجم تا زیر 600 کیلوبایت**
        while (blob && blob.size > 600 * 1024 && quality > 0.2) {
          quality -= 0.1;
          blob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(resolve, 'image/jpeg', quality)
          );
        }

        if (blob) {
          sendImg(blob,txt)
        }
      };
    };
  };

  const sendImg = async (img: any,text:string) => {
    dispatch(DialogShow.hide())
    setLoading(true)
    setTimeout(() => scroolDown(), 200);

    const form_data = new FormData();
    form_data.append("type", "ticket");
    form_data.append("doc", img);

    const result = await gather(`${config.api}/v1/file`).upload(form_data);

    if (result.code === 200) {
      // snackUse.showSaccess("ارسال مدارک با موفقیت انجام شد");
      // send(result?.data?.link)
      // addTextList(result?.data?.link, true);
      send(result?.data?.link, true,text)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setLoading(false)
    }
  }

  const get = async () => {
    dispatch(BackdropShow.showCircle());

    const result = await gather(`${config.api}/v1/ticket/${data?._id}/reply`).get();

    if (result.message === 200) {
      // setListChat(result?.data?.reverse() ?? []);

      let newList: any = []
      let xx: any = null
      Array.from(result?.data?.reverse())?.forEach((item: any) => {
        let newItem = _.cloneDeep(item)
        if (xx !== moment(item?.createdAt).locale("fa").format('DDMMYYYY')) {
          xx = moment(item?.createdAt).locale("fa").format('DDMMYYYY');
          newItem["showTime"] = true
        } else {
          newItem["showTime"] = false
        }

        newList.push(newItem)
      });
      setListChat(newList ?? [])

      setTimeout(() => scroolDown(), 200);
      document.querySelector('.enterMessage')?.scrollIntoView({
        behavior: 'smooth', block: "end", inline: "nearest"
      });

    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hideCircle());
  }

  useEffect(() => {
    if (data?._id) {
      get();
    } else {
      navigate(menu.ticket.childs.history.url)
    }
    return () => {
      fixValue = null
    }
  }, [])


  const changeFix = (item: any) => {
    fixValue = item
  }

  const handleTime = (time: any) => {
    if (fixValue !== moment(time).locale("fa").format('DDMMYYYY')) {
      fixValue = moment(time).locale("fa").format('DDMMYYYY')
      return <div className='titleTime'>
        <p>{moment(time).locale("fa").format('DD MMMM  YYYY')}</p>
      </div>
    } else {
      return ""
    }
  }


  return (
    <div className='chatTicker'>
      <div className='right'>
        <div className='chatList' ref={refBox}>
          <div className='titleTime'>
            <p>{moment(data?.createdAt).locale("fa").format('DD MMMM  YYYY')}</p>
          </div>
          <div className='itemUser'>
            <div className='boxText'>
              <p>{TextWithLineBreaks(data?.text)}</p>
            </div>
            <p className='time'>{moment(data?.createdAt).locale("fa").format('HH:mm')}</p>
          </div>
          {data.file &&
            <div className='itemUser'>
              <div className='boxImg boxText'>
              <img src={data?.file} alt="" className='imgSmall' onClick={() => dispatch(DialogShow.show(<div className='fullScreenImg'>
                  <img src={data?.file} alt="" />
                </div>))} />
              </div>
              <p className='time'>{moment(data?.createdAt).locale("fa").format('HH:mm')}</p>
            </div>}
          {listChat?.map((i: any, n: any) =>
            <Fragment key={n}>
              {i?.showTime && <div className='titleTime'>
                <p>{moment(i?.createdAt).locale("fa").format('DD MMMM  YYYY')}</p>
              </div>}
              {i?.newChat && <div className='titleTime'>
                <p>پیام جدید</p>
              </div>}
              {i?.type !== "user"
                ? <div key={n} className='itemAdmin'>
                  <div className='body'>
                    <div className='boxText'>
                      <div>
                        <h5>پشتیبانی اکسنوین </h5>
                        <img crossOrigin='anonymous' src="/icons/labelof.svg" alt="" />
                      </div>
                      <div className='boxImg'>
                        {i.file && <img className='imgSmall' src={i.file} alt="" height={60} onClick={() => dispatch(DialogShow.show(<div className='fullScreenImg'>
                          <img src={i.file} alt="" />
                        </div>))} />}
                        {i?.text && <p>{TextWithLineBreaks(i?.text)}</p>}
                      </div>
                    </div>
                    <p className='time'>{moment(i?.createdAt).locale("fa").format('HH:mm')}</p>
                  </div>
                  <img crossOrigin='anonymous' src="/icons/exnovinChat.svg" alt="" />
                </div>
                : <div key={n} className='itemUser'>
                  <div className='boxImg boxText' >
                    {i.file && <img className='imgSmall' src={i.file} alt="" height={60}  onClick={() => dispatch(DialogShow.show(<div className='fullScreenImg'>
                      <img src={i.file} alt="" />
                    </div>))} />}
                    {i?.text && <p>{TextWithLineBreaks(i?.text)}</p>}
                  </div>
                  <p className='time'>{moment(i?.createdAt).locale("fa").format('HH:mm')}</p>
                </div>}
            </Fragment>
          )}
          {loading && <div className='itemUser'>
            <div className='boxText'>
              <CircularProgress style={{ color: "#2424da" }} />
            </div>
            <p className='time'>{moment(data?.createdAt).locale("fa").format('LT')}</p>
          </div>}
        </div>
        {data?.status === "close"
          ? <div className='enterMessage'>
            <div className='textMessage w100'>
              <img crossOrigin='anonymous' src="/images/m-IconUser1.png" alt="" />
              <input className='inputChat' type="text" readOnly placeholder='گفتگو به پایان رسیده است' />
            </div>
          </div>
          : <div className='enterMessage'>
            <div className='sendMessage' onClick={() => !loading && send(message, false,"")}>
              <div><img crossOrigin='anonymous' src="/icons/sendpm.svg" alt="" /></div>
            </div>
            <div className='textMessage'>
              <img crossOrigin='anonymous' src="/images/m-IconUser1.png" alt="" />
              <textarea className='inputChat'
                // onKeyUp={(e: any) => (e.key === 'Enter' && e.target.value.length > 0) && (!loading && send(message, false))}
                placeholder='پیام خود را وارد کنید' value={message} onChange={(e: any) => { fixValue = null; setMessage(e.target.value); }} />
            </div>
            <div className='selectFile'>
              <input type="file" accept="image/*" ref={inputFile} onChange={handleFileChange} placeholder='.' />
              <span className='icon-Attachment cPointer' onClick={() => inputFile.current.click()}></span>
            </div>

          </div>}
      </div>
      <div className='left'>
        <div className='headTitle'>
          <div>
            <img crossOrigin='anonymous' src="/icons/CommentsWhite.svg" alt="" />
            <p>تیکت شماره</p>
          </div>
          <h5>{data?.uniqueId}</h5>
        </div>
        <div className='body'>
          <div className='item'>
            <div className='imgborder'><img crossOrigin='anonymous' src="/icons/calenderinfo.svg" alt="" /></div>
            <div className='text'>
              <h4>تاریخ ایجاد</h4>
              <p>{moment(data?.createdAt).locale("fa").format('HH:mm - YYYY-MM-DD')}</p>
            </div>
          </div>
          <div className='item'>
            <div className='imgborder'><img crossOrigin='anonymous' src="/icons/calenderinfo.svg" alt="" /></div>
            <div className='text'>
              <h4>بخش</h4>
              <p>{data?.port ?? "پشتیبانی"}</p>
            </div>
          </div>
          <div className='item'>
            <div className='imgborder'><img crossOrigin='anonymous' src="/icons/calenderinfo.svg" alt="" /></div>
            <div className='text'>
              <h4>موضوع تیکت</h4>
              <p>{data?.title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Chat) 