import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import SwitchCustom from './items/SwitchCustom'
import { NavLink } from 'react-router-dom'
import { menu } from '../../config/menu'
import { useDispatch, useSelector } from 'react-redux'
import ActiveGA1 from './ActiveGA1'
import ActiveGA2 from './ActiveGA2'
import ActiveGA3 from './ActiveGA3'
import _ from 'lodash'
import ActiveGAConfirm from './ActiveGAConfirm'
import gather from '../../helpers/gather'
import { config } from '../../config/config'
import { useTranslation } from 'react-i18next'
import { getProfile, hideShowAssetApi } from '../../services/redux/user'
import useSnack from '../../hooks/useSnack'
import Footer from '../../components/Footer'
import { CircularProgress } from '@mui/material'
import { useLocalStorage } from 'usehooks-ts'

const GoogleAuth = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const profile = useSelector((state: any) => state.user.profile);
  const hideShowAsset = useSelector((state: any) => state.user?.hideShowAsset);
  const [isSundNotif,setIsSundNotif] = useLocalStorage('isSundNotif', "false");
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [checkedPin, setCheckedPin] = useState(false);
  const [checkedLock, setCheckedLock] = useState(false);
  const [switchView, setSwitchView] = useState(0);
  const [myLoadingSms, setMyLoadingSms] = useState(false);
  const [myLoadingAuth, setMyLoadingAuth] = useState(false);

  const callSmsApi = async () => {
    setMyLoadingSms(true);
    await sendApi(true);
    setMyLoadingSms(false);
  }

  const changeView = async (num: any) => {
    if (_.isNull(profile?.verify?.gAuth)) {
      setSwitchView(num);
    } else {
      setMyLoadingAuth(true);
      await sendApi(false);
      setMyLoadingAuth(false);
    }
  }

  const sendApi = async (twoStep: any) => {
    const body: any = {
      "twoStep": twoStep ? "sms" : "gAuth",
      "hideAsset": hideShowAsset
    }

    const result = await gather(`${config.api}/v1/user/setting`).post(body);

    if (result.message === 200) {
      await dispatch(getProfile());
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
  }

  useEffect(() => {
    setCheckedLogin(profile?.setting?.twoStep === 'sms');
  }, [profile])

  useEffect(() => {
    document.title = menu.setting.childs.googleAuth.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          {switchView == 0
            ? <div className='googleAuth'>
              <div className='top'>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <img src="/icons/m-IconGA.svg" alt="" />
                    <div className='text'>
                      <h4>شناسایی دوعاملی</h4>
                      <p>برای برداشت یا ورود به حساب کاربری استفاده می شود</p>
                    </div>
                  </div>
                </div>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <img src="/icons/m-IconSms1.svg" alt="" />
                    <div className='text'>
                      <h4>پیامک</h4>
                      <p className='ltr'>{profile?.mobile?.slice(0, 4)}***{profile?.mobile?.slice(-4, profile?.mobile?.length)}</p>
                      <p> تایید صحت دو مرحله‌ای برای ورود و برداشت دارایی </p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    {
                      myLoadingSms
                        ? <div className="loadingCircular"><CircularProgress size={25} /></div>
                        :
                        <SwitchCustom disabled={false} checked={checkedLogin} setChecked={() => !checkedLogin ? callSmsApi() : snackUse.showError("ابتدا گوگل آتنتیکیتور را فعال کنید")} />
                    }
                  </div>
                </div>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <img src="/icons/m-IconGA2.svg" alt="" />
                    <div className='text'>
                      <h4>گوگل آتنتیکیتور (Google Authenticator)</h4>
                      <p> تایید صحت دو مرحله‌ای برای ورود و برداشت دارایی </p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    {
                      myLoadingAuth
                        ? <div className="loadingCircular"><CircularProgress size={25} /></div>
                        :
                        <SwitchCustom disabled={false} checked={!checkedLogin} setChecked={() => checkedLogin ? changeView(1) : snackUse.showError("ابتدا تایید پیامکی را فعال کنید")} />
                    }
                  </div>
                </div>
              </div>
              <div className='top center'>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <div className='text'>
                      <h4>تغییر رمز عبور ورود</h4>
                      <p>هنگام بازنشانی رمز عبور، به طور خودکار از سیستم خارج می شوید</p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    <NavLink to={menu.setting.childs.changePass.url}>ویرایش</NavLink>
                  </div>
                </div>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <div className='text'>
                      <h4>پنهان کردن دارایی‌ها</h4>
                      <p>با فعال سازی این گزینه دیگر دارایی کیف پولتان بدون کلیک شما نشان داده نمی‌شود</p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    <SwitchCustom disabled={false} checked={hideShowAsset}
                      setChecked={(e: any) => {
                        dispatch(hideShowAssetApi({
                          "twoStep": profile?.setting?.twoStep,
                          "hideAsset": e
                        }))
                      }} />
                  </div>
                </div>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <div className='text'>
                      <h4>پخش صدا اطلاعیه ها</h4>
                      <p>با فعال سازی این گزینه پخش صدا اطلاعیه ها برای شما اعمال می شود</p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    <SwitchCustom disabled={false} checked={isSundNotif == "true" ? true : false}
                      setChecked={(e: any) => {
                        setIsSundNotif(e ? "true" : "false");
                      }} />
                  </div>
                </div>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <div className='text'>
                      <h4>کد پین</h4>
                      <p>با فعال سازی این گزینه می توانید با کد 4 رقمی وارد پنل خود شوید</p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    <SwitchCustom disabled={true} checked={checkedPin} setChecked={setCheckedPin} />
                  </div>
                </div>
                <div className='textItemBox'>
                  <div className='rightEle'>
                    <div className='text'>
                      <h4>قفل دستگاه</h4>
                      <p>با فعال سازی این گزینه می توانید با اثر انگشت یا تشخیص چهره وارد پنل خود شوید</p>
                    </div>
                  </div>
                  <div className='leftEle'>
                    <SwitchCustom disabled={true} checked={checkedLock} setChecked={setCheckedLock} />
                  </div>
                </div>

              </div>
            </div>
            : switchView == 1 ? <ActiveGA1 submit={setSwitchView} />
              : switchView == 2 ? <ActiveGA2 submit={setSwitchView} />
                : switchView == 3 ? <ActiveGA3 submit={setSwitchView} />
                  : <ActiveGAConfirm submit={setSwitchView} />}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default GoogleAuth