import { Checkbox, Divider, Fade, Tooltip } from '@mui/material';
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../config/config';
import _ from 'lodash';
import { autoFormatter, formatter, removeComma, zeroFormat } from '../../helpers/tools';
import { NavLink } from 'react-router-dom';
import { menu } from '../../config/menu';
import DonatChart from '../../components/DonatChart';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { tmnUsdtFilter } from '../../config/variable';
import useBalanceFix from '../../hooks/useBalanceFix';
import FetchListLoad from '../../components/FetchListLoad';
import Footer from '../../components/Footer';
import { hideShowAssetApi } from '../../services/redux/user';
import useSnack from '../../hooks/useSnack';

const Wallet = () => {
  const dispatch = useDispatch<any>();
  const listCoinObjAll: any = useSelector((state: any) => state.publicApis?.listCoinObjAll);
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const profile = useSelector((state: any) => state.user.profile);
  const hideShowAsset = useSelector((state: any) => state.user?.hideShowAsset);
  const [listFix, setListFix]: any = useState([]);
  const [list, setList]: any = useState([]);
  const [chart, setChart] = useState([{ label: "دارایی", value: 100, color: "#000" }]);
  const [checked, setChecked] = React.useState(false);
  const color = ["#1d6800", "#00ff1a", "#000fb9", "#000000"];
  const balance = useBalanceFix();
  const [loading, setLoading] = useState(true);
  const tmnUsdt = ["tmn", "usdt"];
  const listVoucherSymbol = useSelector((state: any) => state.publicApis?.listVoucherSymbol);
  const snackUse = useSnack();

  const minAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setList(Array.from(listFix).filter((e: any) => (e.value.usdt >= 1 || ["tmn", "usdt"].includes(e.symbol))))
    } else {
      setList(listFix);
    }
  }

  const searchItem = (text: any) => {
    if (_.isEmpty(listFix)) return
    const find = Array.from(listFix).filter((e: any) =>
      e?.name?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.symbol?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.nameFa?.toLowerCase()?.includes(text?.toLowerCase()));

    if (find) {
      // const list = find.reduce((a: any, v: any) => ({ ...a, [v?.coin?.symbol]: v }), {});
      setList(find);
    } else {
      setList(listFix);
    }
  }

  const handleAllAmount = () => {
    const myList = Object.values(balance)
      ?.filter((e: any) => !Object?.values(listVoucherSymbol)
        ?.includes(e.symbol))
      ?.sort((a: any, b: any) => b.value.usdt - a.value.usdt)
      ?.sort((a, b) => {
        if (a.symbol === 'tmn' || a.symbol === 'usdt') {
          return -1;
        }
        return b.value.usdt - a.value.usdt;
      })
      ?.sort((a: any, b: any) => b.mySort - a.mySort)
      ?? []

    setListFix(myList)
    setList(myList)

    // let data: any = [{
    //   value: zeroFormat(Object.values(balance)?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))?.reduce((n, e: any) => n + e.value.usdt, 0), 0),
    //   label: "موجودی دلاری", color: color[1], key: 1
    // }]
    let data: any = [{
      key: 1
      , label: "TMN" + "   " + "تومان"
      , value: removeComma(zeroFormat(Number(balance["tmn"]?.value?.usdt), 2, false))
      , color: color[0]
      ,month: 'December',
    },
    {
      key: 2
      , label: "USDT" + "   " + "تتر"
      , value: removeComma(zeroFormat(Number(balance["usdt"]?.value.usdt), 2, false))
      , color: color[1]
      ,month: 'December',
    },
    {
      key: 3
      , label: "سایر ارزها"
      , value: removeComma(zeroFormat(Object.values(balance)
        ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
        ?.filter((e: any) => !tmnUsdtFilter.includes(e.symbol))
        ?.reduce((n, e: any) => n + e.value.usdt, 0), 2, false))
      , color: color[2]
      ,month: 'December',
    }]

    const checkList = data.filter((e: any) => e.value > 0);
    if (checkList?.length > 0) {
      setChart(data)
    }

    setLoading(false);
  }

  const handleSymbol = (mySymbol: string) => {
    if (mySymbol) {
      if (mySymbol?.trim().length > 5) {
        return mySymbol.slice(0, 5)
      } else {
        return mySymbol
      }
    }
    return ""

  }

  useEffect(() => {
    handleAllAmount();
  }, [mainWalletObj])

  useEffect(() => {
    document.title = menu.financial.childs.wallet.title;
  }, []);


  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='walletUser'>
            <div className='bodyBox'>
              <div className='walletAmount'>
                <div className='topText'>
                  <div className='text'>
                    <h4>دارایی کل</h4>
                    <span onClick={() => dispatch(hideShowAssetApi({
                      "twoStep": profile?.setting?.twoStep,
                      "hideAsset": !hideShowAsset
                    }))} className='icon-IconEye cPointer'></span>
                  </div>
                  <NavLink to={menu.financial.childs.transactionHistory.childs.depositCash.url} className='btnText'>
                    <p>تاریخچه تراکنش ها</p>
                    <span className='icon-ArrowLeft'></span>
                  </NavLink>
                </div>
                <div className='totalAmount'>
                  {hideShowAsset ? <h4>&#9733; &#9733; &#9733; &#9733; &#9733; &#9733;</h4>
                    : <>
                      <h4>{formatter(Object.values(balance)
                      ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
                      ?.reduce((n, e: any) => n + e.value.tmn, 0), 0, 0, true)} &nbsp; <b>تومان</b></h4>
                      <span className='icon-ArrowDown'></span>
                    </>}
                </div>
                <Divider className='divider' />
                <div className='profitPast'>
                  <p>سود و زیان روز گذشته</p>
                  <div>
                    <p>پرتفو</p>
                    <span className='icon-ArrowLeft' ></span>
                  </div>
                </div>
                <div className='profitAmount'>
                  <p>0  <b>تومان</b></p>
                  <p className='profitPercent'>0%</p>
                </div>
                <div className='btnControl'>
                  <NavLink className="btnDeposit" to={menu.financial.childs.depositAll.childs.depositCash.childs.default.url}>واریز</NavLink>
                  <NavLink className="btnWidthdraw" to={menu.financial.childs.withdrawAll.childs.withdrawCash.url}>برداشت</NavLink>
                </div>
              </div>
              <div className='walletProgerss'>
                <div className='donatChart'>
                  <DonatChart data={chart} />
                </div>
                <div className='legendChart'>
                  {Array.from(chart)?.map((i, n) =>
                    <div key={n} className='item'>
                      <span className='dot' style={{ background: `${i.color}` }}></span>
                      <p>{i.label == "دارایی" ? "بدون دارایی" : i.label}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='historyBox'>
              <div className='controler'>
                <div className='searchBox'>
                  <span className='icon-iconSearch2'></span>
                  <input onChange={(e) => searchItem(e.target.value)} type="text" placeholder='جستجو کنید' />
                </div>
                <div className='oprator'>
                  <div className='hideMinAmount'>
                    <Checkbox checked={checked} onChange={minAmount} sx={{
                      color: "var(--color-black5)",
                    }} />
                    <p>پنهان کردن موجودی های کوچک</p>
                    <span className='icon-IconInfoCircle'></span>
                  </div>
                  <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} enterDelay={500} leaveDelay={200}
                    title="... به زودی">
                    <div className='changeMinAmount cPointer'>
                      <p>تبدیل موجودی های کوچک</p>
                      <LazyLoadImage src="/icons/m-IconRefreshBlue.svg" alt="" />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className='tb_View tb_Wallet'>
                <table>
                  <thead>
                    <tr>
                      <th><h5>نام ارز</h5></th>
                      <th><h5>مقدار دارایی</h5></th>
                      <th><h5>در دسترس</h5></th>
                      <th><h5>رزرو شده</h5></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className={"relative"}>
                    {!_.isEmpty(list)
                      && Array.from(list)?.map((i: any, n: any) =>
                        <tr key={n}>
                          <td>
                            <div className='name'>
                              {/* <LazyLoadImage crossOrigin='anonymous' src={i?.symbol == "tmn" ? "/icons/iranflag.svg" : `${config.imgUrl}${i?.symbol?.toUpperCase()}.png`} alt="" /> */}
                              <img crossOrigin='anonymous' src={i?.symbol == "tmn" ? "/icons/iranflag.svg" : `${config.imgUrl}${i?.symbol?.toUpperCase()}.png`} alt="" />
                              <div>
                                <h4>{i?.nameFa ?? "- - -"}</h4>
                                <p>{i?.name}</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='priceItem'>
                              {hideShowAsset ?
                                <>
                                  <h4>&#9733; &#9733; &#9733; &#9733; &#9733;</h4>
                                  <p>. </p>
                                </>
                                : <>
                                  <h4>{i?.symbol == "tmn" ? formatter(i?.amount, 0, 0, true) : zeroFormat(i?.amount)}&nbsp;{handleSymbol(i?.symbol?.toUpperCase())}</h4>
                                  {tmnUsdt.includes(i?.symbol?.toLocaleLowerCase())
                                    ? <p>{zeroFormat(i?.value?.usdt, listCoinObjAll[i?.symbol?.toLocaleLowerCase()]?.precision, false)} $</p>
                                    : <p className='ltr'>{zeroFormat(i?.value?.tmn, 0, false)} tmn</p>
                                  }
                                </>}
                            </div>
                          </td>
                          <td>
                            <div className='priceItem'>
                              {hideShowAsset ?
                                <>
                                  <h4>&#9733; &#9733; &#9733; &#9733; &#9733;</h4>
                                  <p>.</p>
                                </>
                                : <>
                                  <h4>{i?.symbol == "tmn" ? formatter(i?.balance, 0, 0, true) : zeroFormat(i?.balance)}&nbsp;{handleSymbol(i?.symbol?.toUpperCase())}</h4>
                                  {tmnUsdt.includes(i?.symbol?.toLocaleLowerCase())
                                    ? <p >{zeroFormat(i?.value?.balanceUsdt, listCoinObjAll[i?.symbol?.toLocaleLowerCase()]?.precision, false)} $</p>
                                    : <p className='ltr'>{zeroFormat(i?.value?.balanceTmn, 0, false)} tmn</p>
                                  }
                                </>}
                            </div>
                          </td>
                          <td>
                            <div className='priceItem'>
                              {hideShowAsset ?
                                <>
                                  <h4>&#9733; &#9733; &#9733; &#9733; &#9733;</h4>
                                  <p>. </p>
                                </>
                                : <>
                                  <h4>{i?.symbol == "tmn" ? formatter(i?.blocked, 0, 0, true) : zeroFormat(i?.blocked)}&nbsp;{handleSymbol(i?.symbol?.toUpperCase())}</h4>
                                  {tmnUsdt.includes(i?.symbol?.toLocaleLowerCase())
                                    ? <p>{zeroFormat(i?.value?.blockedUsdt, listCoinObjAll[i?.symbol?.toLocaleLowerCase()]?.precision, false)} $</p>
                                    : <p className='ltr'>{zeroFormat(i?.value?.blockedTmn, 0, false)} tmn</p>
                                  }
                                </>}
                            </div>
                          </td>
                          {i?.detailCoin?.isActive
                            ? <td >
                              <div className='operator'>
                                {i?.symbol == "tmn"
                                  ? <div className='opItem order'>- - -</div>
                                  : <div className='opItem order'>
                                    <p>مبادله</p>
                                    <div className='hoverBox'>
                                      <NavLink to={menu.order.childs.fastOrder.url + "/" + i?.symbol?.toUpperCase() + "-TMN"}>آسان(فوری)</NavLink>
                                      <NavLink to={menu.order.childs.orederPro.url + "/" + i?.symbol?.toUpperCase() + "-TMN"}>حرفه ای</NavLink>
                                    </div>
                                  </div>}
                                <NavLink
                                  to={i?.symbol == "tmn" ? menu.financial.childs.depositAll.childs.depositCash.childs.default.url : menu.financial.childs.depositAll.childs.depositCoin.url + "/" + i?.symbol?.toUpperCase()}
                                  className='opItem receive'>واریز</NavLink>
                                <NavLink
                                  to={i?.symbol == "tmn" ? menu.financial.childs.withdrawAll.childs.withdrawCash.url : menu.financial.childs.withdrawAll.childs.withdrawCoin.url + "/" + i?.symbol?.toUpperCase()}
                                  className='opItem send'>برداشت</NavLink>
                              </div>
                            </td>
                            : <td >
                              <div className='operator'>
                                <div className='opItem order colorRed' onClick={() => snackUse.showError("ارز مورد نظر غیرفعال می باشد")}>غیرفعال</div>
                                <p className='opItem receive' onClick={() => snackUse.showError("ارز مورد نظر غیرفعال می باشد")}>واریز</p>
                                <p className='opItem send' onClick={() => snackUse.showError("ارز مورد نظر غیرفعال می باشد")}>برداشت</p>
                              </div>
                            </td>}
                        </tr>)}
                  </tbody>
                </table>
                <FetchListLoad list={list} load={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default memo(Wallet);