import { IoMdCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { menu } from "../../config/menu";
import { useNavigate } from "react-router-dom";
import * as MenuState from "../../services/redux/menu";
import ItemNotifcation from "./ItemNotifcation";
import * as PublicApis from "../../services/redux/publicApis";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import SwitchCustom from "../../pages/Profile/items/SwitchCustom";
import { useLocalStorage } from "usehooks-ts";

const Notifcation = ({ flag = true, close,setCount }: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const alarmApi: any = useSelector((state: any) => state.publicApis?.alarmApi);
  const profile = useSelector((state: any) => state.publicApis?.profile);
  const [myLoading, setMyLoading] = useState(false);
  const [countAlarm, setcountAlarm] = useState(0);
  const [isSundNotif, setIsSundNotif] = useLocalStorage('isSundNotif', "false");

  const handleClick = async () => {
    setMyLoading(true);
    await dispatch(PublicApis.profile());
    await dispatch(PublicApis.alarmRead());
    await new Promise(re => setTimeout(re, 700))
    // dispatch(PublicApis.setZeroCountAlarm());
    await dispatch(PublicApis.alarm());
    // countAlert();
    setMyLoading(false);
    setcountAlarm(0);
    setCount(0)
  }


  const countAlert = () => {
    let count = 0;
    Array.from(alarmApi)?.forEach((i: any) => {
      // console.log("aa");
      if (profile?.readNotif) {
        if (i?.createdAt > profile?.readNotif) {
          count += 1
        }
      }
    })
    setcountAlarm(count);
  }
  useEffect(() => {
    countAlert()
  }, [profile, alarmApi]);


  return (
    <div className={`notifPopup ${flag ? "small" : "large"}`}>
      <div className="title">
        {flag ?
          <div className="btns">
            {/* <span>{alarmApi?.length || 0}</span> */}
            {myLoading
              ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#2424da" }} /></div>
              : <button className={countAlarm == 0 ? "disable" : ""} onClick={() => { countAlarm > 0 && handleClick() }}>خواندن همه</button>
            }
          </div>
          : <IoMdCloseCircle className='close' size={20} onClick={close} />}
        <div>
          <p>اعلان ها</p>
          <div className=" ringdrop">
            <span className="icon-ring"></span>
          </div>
        </div>
      </div>
      <div className="changeSound">
        <div className="box">
          <SwitchCustom disabled={false} checked={isSundNotif == "true" ? true : false}
            setChecked={(e: any) => {
              setIsSundNotif(e ? "true" : "false");
            }} />
          <p className="t1">پخش صدا</p>
        </div>
      </div>
      {Array.from(alarmApi)?.slice(0, 3)?.map((i: any, n: number) =>
        <ItemNotifcation key={n} data={i} profile={profile}/>)
      }
      <div className="btnAll" onClick={() => { navigate(menu.notifications.url); dispatch(MenuState.hide()) }}>
        <p>نمایش همه</p>
        <span className="icon-showall"></span>
      </div>
    </div >
  )
}

export default Notifcation