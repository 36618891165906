import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import _ from 'lodash';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import useSnack from '../../hooks/useSnack';
import CoinsBox from '../orderPro/items/CoinsBox';
import ItemChart from './item/ItemChart';
import { NumericFormat } from 'react-number-format';
import { removeComma, toEnglishDigits, toFixed, zeroFormat } from '../../helpers/tools';
import useTicker from '../../hooks/useTicker';
import History from '../orderPro/items/otc/History';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import ConfirmBuySellFast from '../orderPro/dialog/ConfirmBuySellFast';
import * as DialogShow from '../../services/redux/dialog';
import { useTranslation } from 'react-i18next';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { CircularProgress, Divider } from '@mui/material';
import DialogEmptyBalance from '../orderPro/dialog/DialogEmptyBalance';
import { useLocalStorage } from 'usehooks-ts';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import NeedLogin from '../../components/NeedLogin';

const Gold = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const listGoldObj: any = useSelector((state: any) => state.publicApis?.listGoldObj);
  const [selectCoin, setSelectCoin]: any = useState(Object.values(listGoldObj)[0] ?? {});
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const mainWalletObj: any = useSelector((state: any) => state.user?.mainWalletObj);
  const [orderChange, setOrderChange] = useState(true);
  const [firstList, setFirstList]: any = useState({});
  const [reload, setReload] = useState('');
  const snackUse = useSnack();
  const ticker = useTicker();
  const [numTmn, setNumTmn] = useState(0)
  const [numGold, setNumGold] = useState(0);
  const [myLoading, setMyLoading] = useState(false);
  const [confiemOrder] = useLocalStorage('confiemOrder', "false");



  const resetState = () => {
    setNumTmn(0);
    setNumGold(0);
  }

  const handleSelectCoin = (e: any) => {
    if (!e?.isActive) {
      snackUse.showError("ارز مورد نظر غیرفعال می باشد");
      return
    }

    setSelectCoin(e)
    const newList = _.cloneDeep(firstList)
    delete newList[e.symbol];
    if (Object.keys(newList).length > 5) {
      delete newList[Object.keys(newList)[0]];
    }
    newList[e?.symbol] = e;
    setFirstList(newList);
  }

  const handelQuery = (item: any) => {
    if (!item?.isActive) {
      snackUse.showError("ارز مورد نظر غیرفعال می باشد");
      return
    }
    setSelectCoin(item);
    navigate(menu.order.childs.gold.url + "/" + item?.symbol?.toUpperCase() + "-TMN")
    resetState()
  }

  const createList = () => {
    let newList: any = {}
    Array.from(Object.keys(listGoldObj)).forEach((item: any, n: number) => {
      if (n < 6) {
        newList[item] = listGoldObj[item]
      }
    });

    setFirstList(newList);
    setSelectCoin(newList[Object.keys(newList)[0]])
  }

  const handleGramGold = (e: any) => {
    setNumGold(removeComma(e));
    let price = 0
    if (orderChange) {
      price = Number(removeComma(e)) / Number(ticker.buy((selectCoin?.symbol?.toUpperCase() ?? "XAUT") + 'tmn'))
    } else {
      price = Number(removeComma(e)) * Number(ticker.sell((selectCoin?.symbol?.toUpperCase() ?? "XAUT") + 'tmn'))
    }

    setNumTmn(price ?? 0)
  }

  const handlePriceTMN = (e: any) => {
    setNumTmn(removeComma(e));
    let amount = 0
    if (orderChange) {
      amount = Number(removeComma(e)) * Number(ticker.buy((selectCoin?.symbol?.toUpperCase() ?? "XAUT") + 'tmn'))
    } else {
      amount = Number(removeComma(e)) / Number(ticker.sell((selectCoin?.symbol?.toUpperCase() ?? "XAUT") + 'tmn'))
    }
    setNumGold(amount ?? 0)
  }


  const confirmSend = () => {

    const myAmount = orderChange ? Number(toEnglishDigits(numTmn?.toString()))
      : Number(toEnglishDigits(numGold?.toString()));

    const myBuySell = orderChange ? Number(toEnglishDigits(numGold?.toString()))
      : Number(toEnglishDigits(numTmn?.toString()));


    if (Number(mainWalletObj[orderChange ? 'tmn' : selectCoin?.symbol]?.free ?? 0) < Number(toEnglishDigits(numGold.toString()))) {
      dispatch(DialogShow.show(<DialogEmptyBalance symbol={orderChange ? "tmn" : selectCoin?.symbol} />))
      return
    }

    if (_.isEmpty(selectCoin)) {
      snackUse.showError('لطفا یک ارز را انتخاب کنید');
      return
    }

    if (myAmount <= 0) {
      snackUse.showError('مقدار را وارد کنید');
      return
    }


    if (confiemOrder == "true") {
      send({
        'coin': selectCoin._id,
        'pair': 'tmn',
        'side': orderChange ? "buy" : "sell",
        'price': ticker[orderChange ? "buy" : "sell"](selectCoin?.symbol + 'tmn'),
        "amount": orderChange
          ? toFixed(myBuySell / ticker['buy'](selectCoin?.symbol + 'tmn'), selectCoin?.decimal)
          : toFixed(numGold, selectCoin?.decimal)
        ,
        "trade": "otc",
        "stop": null
      })
    } else {
      const data = {
        "coin": selectCoin,
        "side": orderChange ? "buy" : "sell",
        'amount': myAmount,
        "radioBuy": "defualt",
        "network": "",
        "needTagMemo": "",
        "address": "",
        "tagMemo": "",
        "myBuySell": myBuySell
      }
      dispatch(DialogShow.show(<ConfirmBuySellFast data={data} send={send} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const send = async (body: any) => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/order`).post(body);
    dispatch(DialogShow.hide())

    if (result.message === 200) {
      setReload(new Date().getTime().toString());
      snackUse.showSaccess('سفارش شما با موفقیت ثبت شد');
      // setAmount(0);
      // setPayment(0);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }


  useEffect(() => {
    createList();
  }, [listGoldObj])

  useEffect(() => {
    document.title = menu.order.childs.gold.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='order orderGold'>
            <div className='bannerGold'>
              <div className='txtBox'>
                <p className='p1'>طلا، اما دیجیتال!</p>
                <p className='p2'> تترگلد و پکس گلد – سرمایه‌گذاری امن، نقدشوندگی سریع و بدون دغدغه نگهداری</p>
              </div>
            </div>
            <div className='stepOrder' onClick={() => console.log(selectCoin)}>
              <div>
                <span className='icon-Stack'></span>
                <h4> <b>انتخاب ارز</b></h4>
                <div className='dividerVer'></div>
              </div>
              <p>ارز موردنظر را انتخاب کنید</p>
            </div>
            <CoinsBox listCoin={listGoldObj} firstList={firstList} handleSelectCoin={handleSelectCoin}
              setOrderChange={setOrderChange} selectCoin={selectCoin} setSelectCoin={(e: any) => { handelQuery(e) }} />
            <div className='bodyGold'>
              <div className='chartGolg'>
                <p className='tit1'>نمودار قیمت {selectCoin?.nameFa ?? "--"}</p>
                <div className='boxChart'>
                  <ItemChart symbol={(selectCoin?.symbol?.toUpperCase() ?? "XAUT") + "USDT"} exchange={"BINANCE"} />
                </div>
              </div>
              <div className='calculaGold'>
                <p className='tit1'>تبدیل ارز</p>
                <div className='boxDetail'>
                  <div className='btns'>
                    <button className={`btnBuy ${orderChange ? "active" : ""}`}
                      onClick={() => { setOrderChange(true); resetState() }}
                    >خرید {selectCoin?.nameFa ?? "--"}</button>
                    <button className={`btnSell ${orderChange ? "" : "active"}`}
                      onClick={() => { setOrderChange(false); resetState() }}
                    >فروش {selectCoin?.nameFa ?? "--"}</button>
                  </div>
                  <div className='balanceNum'>
                    <p className='p1'>موجودی : </p>
                    <NavLink
                      to={orderChange ? menu.financial.childs.depositAll.childs.depositCash.childs.default.url : menu.financial.childs.depositAll.childs.depositCoin.url + "/" + selectCoin?.symbol?.toUpperCase()}
                      className='cPointer flexCenter ml-5'>
                      <IoIosAddCircleOutline size={21} color='var(--color-textDone)' />
                    </NavLink>
                    <h4 className='cPointer mr-3' style={{ fontSize: "12px", marginLeft: "4px" }}
                      onClick={() => { handleGramGold(mainWalletObj[orderChange ? 'tmn' : selectCoin?.symbol]?.free) }}
                    >
                      {zeroFormat(mainWalletObj[orderChange ? 'tmn' : selectCoin?.symbol]?.free ?? 0)}
                    </h4>
                    <h4 className='mr-2' style={{ fontSize: "12px" }}>{orderChange ? 'TMN' : selectCoin?.symbol?.slice(0, 4)?.toUpperCase()}</h4>
                  </div>
                  <span className='t2'>پرداخت می کنم</span>
                  <div className='numBox'>
                    <NumericFormat allowNegative={false} thousandSeparator={true} decimalScale={!orderChange ? selectCoin?.decimal : 0}
                      value={numGold > 0 ? numGold : ''} onChange={(e) => handleGramGold(e.target.value)}
                      placeholder={!orderChange ? `مقدار ${selectCoin?.nameFa ?? "--"}` : "مقدار تومان"} />
                    <span className='s1'>{!orderChange ? (selectCoin?.symbol?.toUpperCase() ?? "XAUT") : "تومان"}</span>
                  </div>
                  <span className='t2'>دریافت می کنم</span>
                  <div className='numBox'>
                    <NumericFormat allowNegative={false} thousandSeparator={true} decimalScale={orderChange ? selectCoin?.decimal : 0}
                      value={numTmn > 0 ? numTmn : ''} onChange={(e) => handlePriceTMN(e.target.value)}
                      placeholder={orderChange ? `مقدار ${selectCoin?.nameFa ?? "--"}` : "مقدار تومان"} />
                    <span className='s1'>{orderChange ? (selectCoin?.symbol?.toUpperCase() ?? "XAUT") : "تومان"}</span>
                  </div>
                  <div className='priceNow'>
                    <div className='txBox'>
                      <span className={`icon-dotGreen ${orderChange ? "" : "bgRed"}`}></span>
                      <span className='t2'>قیمت روز طلا</span>
                    </div>
                    <span className='t2'>{`${zeroFormat(ticker[orderChange ? 'buy' : 'sell']((selectCoin?.symbol?.toUpperCase() ?? "XAUT") + 'tmn'))}  تومان `}</span>
                  </div>
                  <div className='submitBtn'>
                    {userIsLogin
                      ? myLoading
                        ? <div className={`loadingCircularOrder ${orderChange ? 'buy' : 'sell'}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                        : <button className={`btnn ${orderChange ? "bgGreen" : "bgRed1"}`} onClick={confirmSend}
                        >تایید {orderChange ? "خرید" : "فروش"}
                        </button>
                      : <div className={`btnConfirm justifyCenter ${orderChange ? 'buy' : 'sell'}`}
                        onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}>
                        <p className='textCenter'>ثبت نام - ورود</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="tableDetail">
              <table>
                <thead>
                  <tr>
                    <th className='colorGold col1'>مزایای خرید طلای دیجیتال</th>
                    <th >طلای دیجیتال</th>
                    <th>
                      <div className='box1'>
                        <img src="/images/shamshGold2.svg" alt="" />
                        <p className=''>طلای فیزیکی</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className='boxItem col1'>
                        <span className='icon-24_support'></span>
                        <p className='p1'>امکان معامله ۲۴ ساعته</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-tick_circle'></span>
                        <p className='p1 colorGreen'>دارد</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-close_circle'></span>
                        <p className='p1 colorRed'>ندارد</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='boxItem col1'>
                        <span className='icon-arrange_square'></span>
                        <p className='p1'>نوع معامله</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-tick_circle'></span>
                        <p className='p1 colorGreen'>آنلاین</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-close_circle'></span>
                        <p className='p1 colorRed'>حضوری</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='boxItem col1'>
                        <span className='icon-shield_security'></span>
                        <p className='p1'>امنیت و نگهداری</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-tick_circle'></span>
                        <p className='p1 colorGreen'>ولت دیجیتال</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-close_circle'></span>
                        <p className='p1 colorRed'>درون منزل</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='boxItem col1'>
                        <span className='icon-discount_circle'></span>
                        <p className='p1'>حباب قیمتی</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-tick_circle'></span>
                        <p className='p1 colorGreen'>۰٪</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-close_circle'></span>
                        <p className='p1 colorRed'>۰.۹۰٪</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='boxItem col1'>
                        <span className='icon-receipt_text'></span>
                        <p className='p1'>پرداخت کارمزد</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-tick_circle'></span>
                        <p className='p1 colorGreen'>۰٪</p>
                      </div>
                    </td>
                    <td>
                      <div className='boxItem'>
                        <span className='icon-close_circle'></span>
                        <p className='p1 colorRed'>۳٪</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {userIsLogin && <History reload={reload} setReload={setReload} />}
        </div>
      </div >
      <Footer />
    </>

  )
}

export default Gold