import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const useSnack = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSundNotif] = useLocalStorage('isSundNotif', "false");
  const [isFalg, setIsFalg] = useState(true);



  const playSound = () => {

    if (isSundNotif == "true" && isFalg) {
      const audio = new Audio('/sounds/notification.mp3');
      audio.play().catch(err => console.error("Audio play error:", err));
      setIsFalg(false)
      setTimeout(() => {
        setIsFalg(true)
      }, 1000);
    }
  }

  const showError = (text: string) => {
    enqueueSnackbar(text, { variant: "error" })
    if (!text?.includes("کپی")) {
      playSound();
    }
  }

  const showSaccess = (text: string) => {
    enqueueSnackbar(text, { variant: "success" })
    if (!text?.includes("کپی")) {
      playSound();
    }
  }

  return ({ showError, showSaccess })
}

export default useSnack;
