import { Socket, io } from 'socket.io-client';
import { config } from '../../config/config';
import { cookieAccessToken } from '../../helpers/authUser';
import { updateTicker } from '../redux/ticker';
import { getProfile, updateAsset, updateBalance } from '../redux/user';
import * as DataSaver from '../redux/dataSaver';

let socket: Socket;
let time: Date = new Date();

const connect = (dispatch: any, t: any, snackUse: any) => {
  if (!socket?.connected) {
    const token = cookieAccessToken() ?? ''

    socket = io(config.api, {
      query: {
        authorization: token?.toString(),
      },
      extraHeaders: {
        authorization: token?.toString(),
      },
      transports: ['websocket'],
    });

    if (new Date().getTime() - time.getTime() >= 20 * 1000) {
      window.location.reload();
    }
  }

  socket.on('connect', () => {
    // console.log('socket', 'Connected');
  });

  socket.on('disconnect', () => {
    // console.log('socket', 'Disconnected');
    socket.removeAllListeners();
    socket.close();
    time = new Date();
    setTimeout(() => {
      connect(dispatch,t,snackUse);
    }, 700);
  });

  socket.on('connect_failed', () => {
    // console.log('socket', 'Failed');
  })

  socket.on('connect_error', (e) => {
    // console.log('socket', 'Error: ' + e);
  })

  socket.on('ticker', (result) => {
    // console.log("socket ticker : ", result)
    // dispatch(PublicApis.addTicker(result));
    dispatch(updateTicker(result));
  });

  socket.on('updateProfile', () => {
    dispatch(getProfile());
  });

  socket.on('balance', (result) => {
    // console.log("socket balance : ", result);
    dispatch(updateBalance(result));
    dispatch(getProfile())
    // if (new Date().getTime() - time.getTime() >= 30 * 1000) {
    // }
  });

  socket.on('asset', (result) => {
    // console.log("socket asset : ", result);
    dispatch(updateAsset(result));
    dispatch(DataSaver.reloadApiOrder(result));
    dispatch(getProfile())
    // if (new Date().getTime() - time.getTime() >= 30 * 1000) {
    // }
  });

  socket.on('order', (result) => {
    console.log("order :",result);
    dispatch(DataSaver.handleSockerOrderData(result));
  });
  

  socket.on('version', (result) => {
    // console.log("version",result);
    dispatch(DataSaver.getVersionUpdate(result));
  });

  socket.on('notif', (result) => {
    // console.log("socket notif : ", result)
    if (result.type === 'error') {
      snackUse.showError(t(result.message) || t("99999"));
    } else {
      snackUse.showSaccess(t(result.message) || t("99999"));
    }
  });

  socket.on('alert', (result) => {
    // console.log("socket alert : ", result)
    snackUse.showError(t(result.message) || t("99999"));
  });

}

const reconnect = (dispatch: any, t: any, snackUse: any) => {
  socket.disconnect();
  connect(dispatch, t, snackUse);
}

const disconnect = () => {
  socket.disconnect();
}

export {
  connect,
  disconnect,
  reconnect,
  socket
};
