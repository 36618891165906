// import '././asset/css/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Alert, Backdrop, CircularProgress, Dialog, Drawer, Menu, Snackbar } from '@mui/material';
import { cookieRefreshToken, logOut } from './helpers/authUser';
import { useDispatch, useSelector } from 'react-redux';
import * as BackdropShow from './services/redux/backdrop';
import * as SnackbarShow from './services/redux/snackbar';
import * as PublicApis from './services/redux/publicApis';
import * as DialogShow from './services/redux/dialog';
import * as DrawerState from './services/redux/drawer';
import * as MenuState from './services/redux/menu';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { config } from './config/config';
import gather from './helpers/gather';
import Main from './pages/Main';
import Cookies from 'js-cookie';
import { connect } from './services/socket';
import { useLocalStorage } from 'usehooks-ts'
import { getTickers } from './services/redux/ticker';
import { getAccountBalance, getCards, getFavorite, getProfile, getWalletBalance } from './services/redux/user';
import * as DataSaver from './services/redux/dataSaver';
import useSnack from './hooks/useSnack';
import { useTranslation } from 'react-i18next';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebase/firebaseConfig';
import { toast, ToastContainer } from "react-toastify";
import MessageNotif from './components/MessageNotif';
import OfflineNotification from './components/OfflineNotification';
import TicketOnline from './components/TicketOnline';
import NotificationPrompt from './components/NotificationPrompt';

const styles = {
  updateDialog: {
    position: "fixed",
    top: "20px",
    right: "20px",
    backgroundColor: "var(--dialog-bg, #ffffff)",
    color: "var(--dialog-text, #000000)",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "16px",
    zIndex: 1000,
    width: "300px",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "8px",
  },
  dialogText: {
    margin: 0,
    fontSize: "16px",
    fontWeight: "bold",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    fontSize: "28px",
    cursor: "pointer",
    marginLeft: "15px",
    color: "var(--dialog-close, #888888)",
  },
  updateButton: {
    backgroundColor: "var(--button-bg, #007BFF)",
    color: "var(--button-text, #ffffff)",
    border: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "100%",
    marginTop: "8px",
    marginBottom: "4px",
  },
};

const App = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const dialogSelector = useSelector((state: any) => state.dialog);
  const snackbarSelector = useSelector((state: any) => state.snackbar);
  const backdropSelector = useSelector((state: any) => state.backdrop);
  const menuSelector = useSelector((state: any) => state.menu);
  const drawerSelector = useSelector((state: any) => state.drawer);
  const versionUpdate = useSelector((state: any) => state.dataSaver?.versionUpdate);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [theme] = useLocalStorage('themeEX', "light");
  const [warningRule, setWarningRule] = useLocalStorage('alertRule', "");
  const snackUse = useSnack();
  const { t } = useTranslation();
  const [showPopNotif, setShowPopNotif] = useState(false);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [isSundNotif] = useLocalStorage('isSundNotif', "false");

  const getPublicData = async () => {
    await Promise.allSettled([
      dispatch(PublicApis.listCoin()),
      dispatch(PublicApis.listNetwork()),
      dispatch(getTickers()),
      dispatch(PublicApis.listLevel()),
      dispatch(PublicApis.setting()),
      dispatch(PublicApis.listCoinNEW()),

      // dispatch(PublicApis.pageLearn()),
    ]);
  }

  const getUserData = async () => {
    await Promise.allSettled([
      dispatch(getProfile()),
      dispatch(getWalletBalance()),
      dispatch(getAccountBalance()),
      dispatch(PublicApis.codeUp25()),
      dispatch(getCards()),
      dispatch(getFavorite()),
      dispatch(PublicApis.alarm()),
      dispatch(PublicApis.notice()),
      dispatch(PublicApis.ticket()),
    ]);
    dispatch(BackdropShow.hide());
  }

  onMessage(messaging, (payload) => {
    if (isSundNotif=="true") {
      const audio = new Audio('/sounds/notification.mp3');
      audio.play();
    }

    toast.info(<MessageNotif notification={payload.notification} />);
  });

  const checkAuth = async () => {
    dispatch(BackdropShow.show());

    await getPublicData();

    if (Cookies.get('refreshTokenPanelEx')) {
      const result = await gather(`${config.api}/v1/auth/refreshToken`).get({ 'Authorization': cookieRefreshToken() });
      if (result) {
        if (result.code === 200) {
          Cookies.set('accessTokenPanelEx', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
          dispatch(DataSaver.changeUserIsLogin(true));
          await getUserData();

        } else {
          if (result.message == 401 || result.code == 401) {
            logOut();
          }
          dispatch(DataSaver.changeUserIsLogin(false))
          navigate("/");
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await checkAuth();
      }
    } else {
      dispatch(DataSaver.changeUserIsLogin(false))
    }

    dispatch(BackdropShow.hide());
    dispatch(DataSaver.changeUserIsReady(true));
    setIsReady(true);

    if (Notification.permission === "granted" || Notification.permission === "denied") {
      setShowPopNotif(false);
    } else {
      setShowPopNotif(true)
    }
  }

  const sendFCM = async (tokenFCM: any) => {
    const result = await gather(`${config.api}/v1/user/fcm`).post({ 'token': tokenFCM });

    if (result.message === 200) {
      console.log("fcm ثبت شد");
    } else {
      console.log(t(result.message) || t("99999"));
    }
  }

  const requestNotification = async () => {
    await Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        snackUse.showSaccess("اعلان‌ها فعال شد");
        setShowPopNotif(false);

        try {
          const currentToken = await getToken(messaging, {
            vapidKey: 'BOFi9Vqhl0gmqvElW6J5d4lm4NRLq5-cokiUi-xPXEXBUuZAlQirQsH_bWGOXbMLOAJiazGNw_p9YK15T3qKoPQ',
          });

          if (!currentToken) {
            console.log("No registration token available.");
            return;
          }

          console.log("Token:", currentToken);

          if (userIsLogin) {
            // await sendFCM(currentToken);
            sendFCM(currentToken);
          }
        } catch (err) {
          console.log("An error occurred while retrieving the token:", err);
          setShowPopNotif(false);
        }
      } else {
        snackUse.showError("مجوز اعلان رد شده است ");
        setShowPopNotif(false);
      }
    });
  };

  const handleUpdate = () => {
    localStorage.removeItem("appVerPalneEX");
    window.location.reload();
  };

  const handleClose = () => {
    setIsUpdateAvailable(false);
  };

  useEffect(() => {
    if (!warningRule) {
      setWarningRule("true");
    }
    connect(dispatch, t, snackUse);
    checkAuth();
  }, [])

  useEffect(() => {
    document.getElementById('MY-BODY')?.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    if (versionUpdate?.trim().length > 0) {
      const currentVersion = localStorage.getItem("appVerPalneEX");
      if (!currentVersion) return
      if (currentVersion !== versionUpdate) {
        setIsUpdateAvailable(true);
        setTimeout(() => {
          setIsUpdateAvailable(false);
        }, 30000);
      }
    }
  }, [versionUpdate]);


  return (
    <div className='App' data-theme={theme}>
      {showPopNotif && <NotificationPrompt submit={() => requestNotification()} setShow={setShowPopNotif} />}
      <OfflineNotification />

      <Drawer open={drawerSelector.open} PaperProps={{ style: drawerSelector.size }} anchor={drawerSelector.anchor} onClose={() => dispatch(DrawerState.hide())}>
        {drawerSelector.content}
      </Drawer>
      <Dialog className='DialogStyle'
        //  TransitionComponent={dialogSelector.transition} 
        PaperProps={{ style: { width: "unset ", borderRadius: "20px" } }} open={dialogSelector.open} onClose={() => dispatch(DialogShow.hide())} maxWidth={false} fullWidth={true} >
        {dialogSelector.content}
      </Dialog>
      <Dialog className='DialogStyle'
        PaperProps={{ style: { width: "unset ", borderRadius: "20px" } }} open={dialogSelector.open2}
        // onClose={() => dispatch(DialogShow.hide2())}
        maxWidth={false} fullWidth={true} >
        {dialogSelector.content2}
      </Dialog>
      <Snackbar
        open={snackbarSelector.open}
        autoHideDuration={3000}
        onClose={() => dispatch(SnackbarShow.hide())}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} >
        <Alert variant="filled" severity={snackbarSelector.severity} sx={{ width: '100%' }}> &nbsp; {snackbarSelector.text}</Alert>
      </Snackbar>
      <Backdrop open={backdropSelector.open} className='BackdropStyle' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1030 }}>
        <video className='imgMobile' muted autoPlay loop >
          <source src="/video/loadingEX.mp4" type="video/mp4" />
        </video>
        {/* <CircularProgress style={{ color: "#2424da" }} /> */}
      </Backdrop>
      <Backdrop open={backdropSelector.openCircle} className='BackdropStyle' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1030 }}>
        <CircularProgress style={{ color: "#2424da" }} />
      </Backdrop>
      <Menu
        id="basic-menu"
        anchorEl={menuSelector.anchorEl}
        open={menuSelector.open}
        onClose={() => dispatch(MenuState.hide())}
        anchorOrigin={{ vertical: 'bottom', horizontal: menuSelector.horizontal }}
        transformOrigin={{ vertical: 'top', horizontal: menuSelector.horizontal }}
        MenuListProps={{ 'aria-labelledby': 'lock-button', role: 'listbox', }}>
        {menuSelector.content}
      </Menu>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme}
      // transition:Bounce
      />
      {isReady && <Main />}
      {isUpdateAvailable && (
        <div className='updateDialogNEW'>
          <div style={styles.dialogHeader}>
            <p className='rtl' style={styles.dialogText}>به‌روزرسانی جدید برای پنل کاربری آماده است!</p>
            <button style={styles.closeButton} onClick={handleClose}>
              ×
            </button>
          </div>
          <button style={styles.updateButton} onClick={handleUpdate}>
            به‌روزرسانی
          </button>
          <span>v{versionUpdate}</span>
        </div>
      )}
      <TicketOnline />
    </div>
  );
}




export default App;
