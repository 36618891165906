import React, { useEffect, useState } from 'react'
import FastOrder from './items/otc/FastOrder';
import FixedPriceOrder from './items/otc/FixedPriceOrder';
import { useSelector } from 'react-redux';
import { config } from '../../config/config';
import History from './items/otc/History';
import { Divider } from '@mui/material';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import _ from 'lodash';
import CoinsBox from './items/CoinsBox';
import { useNavigate, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useCoinPrice from '../../hooks/useCoinPrice';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import useSnack from '../../hooks/useSnack';

const Order = () => {
  const { coinsList, coinsPairList } = useCoinPrice();
  const listCoinObj: any = useSelector((state: any) => state.publicApis?.listCoinObj);
  const listCoin: any = useSelector((state: any) => state.publicApis?.listCoin);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [firstList, setFirstList]: any = useState({});
  const [selectCoin, setSelectCoin]: any = useState({});
  const [orderChange, setOrderChange] = useState(true);
  const navigate = useNavigate();
  const { coinParam } = useParams();
  const [reload, setReload] = useState('');
  const snackUse = useSnack();


  const createList = () => {
    let newList: any = {}
    Array.from(listCoin)?.filter((ele:any) => ele?.isActive)?.forEach((item: any, n: number) => {
      if (n < 6) {
        newList[item?.symbol] = item;
      }
    });

    const coinParams = coinParam?.split("-")[0]?.toLocaleLowerCase();


    if (coinParams) {
      const coin = listCoinObj[coinParams?.toLocaleLowerCase()];
      if (coin && coin?.isActive) {
        setSelectCoin(coin)
        // delete newList[coin?.symbol];
        // if (Object.keys(newList).length > 5) {
        //   delete newList[Object.keys(newList)[0]];
        // }
        // newList[coin?.symbol] = coin;

        ///
        if (Object.keys(newList).length > 5) {
          delete newList[Object.keys(newList)[Object.keys(newList).length - 1]];
        }
        let arryTest: any = Object.values(newList);
        arryTest.unshift(coin);

        let objTest: any = {}

        Array.from(arryTest)?.forEach((element: any) => {
          objTest[element?.symbol] = element
        });

        newList = objTest



        ////
      } else {
        // setSelectCoin(newList[Object.keys(newList)[Object.keys(newList).length - 1]])
        setSelectCoin(newList[Object.keys(newList)[0]])
        if (!coin?.isActive) {
          snackUse.showError("ارز مورد نظر غیرفعال می باشد");
        }
      }
    } else {
      // setSelectCoin(newList[Object.keys(newList)[Object.keys(newList).length - 1]])
      setSelectCoin(newList[Object.keys(newList)[0]])
    }

    setFirstList(newList);
  }

  const handleSelectCoin = (e: any) => {
    if (!e?.isActive) {
      snackUse.showError("ارز مورد نظر غیرفعال می باشد");
      return
    }
    setSelectCoin(e);
    handelQuery(e);
    const newTest = _.cloneDeep(firstList);
    delete newTest[e.symbol];
    if (Object.keys(newTest).length > 5) {
      delete newTest[Object.keys(newTest)[Object.keys(newTest).length - 1]];
    }
    let arryTest: any = Object.values(newTest);
    arryTest.unshift(e);

    let objTest: any = {}

    Array.from(arryTest)?.forEach((element: any) => {
      objTest[element?.symbol] = element
    });

    setFirstList(objTest);
  }

  const handelQuery = (item: any) => {
    if (!item?.isActive) {
      snackUse.showError("ارز مورد نظر غیرفعال می باشد");
      return
    }
    setSelectCoin(item);
    navigate(menu.order.childs.fastOrder.url + "/" + item?.symbol?.toUpperCase() + "-TMN")
  }

  useEffect(() => {
    createList();
  }, [listCoin])

  return (
    <>
      <div className='bodyMain' >
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='order'>
            {/* <div className='btnChangeType'>
              <button className={`${changeType && "active"}`} onClick={() => setChangeType(true)}>تبدیل فوری</button>
              <Divider variant="middle" orientation="vertical" flexItem />
              <button className={`${!changeType && "active"}`} onClick={() => setChangeType(false)}>معامله با قیمت معین</button>
            </div> */}
            <div className='stepOrder'>
              <div>
                <span className='icon-Stack'></span>
                <h4>مرحله اول <b>نوع ارز</b></h4>
                <div className='dividerVer'></div>
              </div>
              <p>ارز موردنظر را انتخاب کنید</p>
            </div>
            <CoinsBox listCoin={coinsList() ?? {}} firstList={firstList} handleSelectCoin={handleSelectCoin}
              setOrderChange={setOrderChange} selectCoin={selectCoin} setSelectCoin={(e: any) => { handelQuery(e) }} />
            {selectCoin?.symbol &&
              <div className='choosingCoin'>
                <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${selectCoin?.symbol?.toUpperCase()}.png`} alt="" />
                <h4>شما در حال معامله <b>{selectCoin?.nameFa ?? "---"}</b> می باشید</h4>
              </div>}
            <FastOrder setReload={setReload} orderChange={orderChange} setOrderChange={setOrderChange} coin={selectCoin} />
            {/* {changeType
              ? <FastOrder setReload={setReload} orderChange={orderChange} setOrderChange={setOrderChange} coin={selectCoin} />
              : <FixedPriceOrder setReload={setReload} orderChange={orderChange} setOrderChange={setOrderChange} coin={selectCoin} />} */}
          </div>
          {userIsLogin && <History reload={reload} setReload={setReload} />}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Order