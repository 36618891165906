import React, { useState, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config/config'
import { menu } from '../../../config/menu'
import FetchListLoad from '../../../components/FetchListLoad'
import { CgChevronLeftR } from 'react-icons/cg'
import { crateQuerySearch, toEnglishDigits, zeroFormat } from '../../../helpers/tools'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'jalali-moment'
import _ from 'lodash'
import { orderStatusFA, sideFA, statusFA, typeOrder2FA } from '../../../config/variable'
import * as DialogShow from '../../../services/redux/dialog'
import gather from '../../../helpers/gather'
import FilterWithID from './FilterWithID'
import DetailOrders from '../../orderPro/dialog/DetailOrders'
import { Pagination } from '@mui/material'

const OrderHistory = () => {
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const dispatch = useDispatch<any>();
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [reloadApi, setReloadApi] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState({
    fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
    toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
  });
  const listStatus = [{ name: "انجام شده", value: "complete" },
  { name: "منتظر", value: "waiting" },
  { name: "باز", value: "pending" },
  { name: "لغو شده", value: "cancel" },
  ]

  const get = async (quList: any) => {
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/order?sortBy=createdAt&orderBy=desc${crateQuerySearch(quList)}`).get()

    if (result.message === 200) {
      setListHistory(result?.data ?? []);
      // setPageCount(result?.data?.pages?.count ?? 1);
      // setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  const createQuery = () => {
    let newListQuery = [...[{ key: "page", value: pageCurrent }], ...Object.values(advancedSearch)]
    get(newListQuery);
  }

  useEffect(() => {
    createQuery();
  }, [reloadApi])

  useEffect(() => {
    document.title = menu.financial.childs.transactionHistory.childs.depositCoin.title;
  }, []);

  return (
    <>
      <FilterWithID showCoin={true} listStatus={listStatus} showType={false} showSide={true}
        hanleSelect={(e: any) => {
          setAdvancedSearch(e); setTimeout(() => {
            setPageCurrent(1);
            setReloadApi(new Date().getTime().toString());
          }, 100);
        }}
        listCoinObj={listCoinObj} />
      <div className={`historyBoxAll allHistoryStyle`}>
        <div className='tb_View tb_OrderPro'>
          <table>
            <thead>
              <tr>
                <th><h5>شماره سفارش</h5></th>
                <th><h5>بازار</h5></th>
                <th><h5>زمان</h5></th>
                <th><h5>نوع معامله</h5></th>
                <th><h5>سمت</h5></th>
                <th><h5>قیمت</h5></th>
                <th><h5>مقدار</h5></th>
                <th><h5>مبلغ کل</h5></th>
                <th><h5>درصد انجام</h5></th>
                <th><h5>وضعیت</h5></th>
                <th></th>
              </tr >
            </thead >
            <tbody className={"relative"}>
              {(!loadHistory && !_.isEmpty(listHistory))
                && Array.from(listHistory)?.map((i: any, n: any) =>
                  <tr key={n} className="box">
                    <td>{i?.uniqueId}</td>
                    <td>
                      <div id='name'>
                        <div className='imgPair'>
                          {/* <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" /> */}
                          <LazyLoadImage crossOrigin='anonymous' className='coin' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" />
                          <LazyLoadImage crossOrigin='anonymous' className='pai' src="/icons/iranflag.svg" alt="" />
                        </div>
                        <p>{i?.pair?.toUpperCase()}/</p>
                        <h5>{i?.coin?.symbol?.toUpperCase() ?? "---"}</h5>
                      </div>
                    </td>
                    <td>{toEnglishDigits(moment(i?.createdAt).locale("fa").format('HH:mm | YYYY-MM-DD'))}</td>
                    <td>{typeOrder2FA[i?.trade]}</td>
                    <td className={`color-${i?.side}`}>{sideFA[i?.side]}</td>
                    <td>{zeroFormat(i?.price)} <b>{i?.pair} </b></td>
                    <td>{zeroFormat(i?.amount)}  <b>{i?.coin?.symbol?.toUpperCase()} </b></td>
                    {/* <td>{i?.stop?zeroFormat(i?.stop):"---"}</td> */}
                    <td>{zeroFormat(i?.total)} <b>{i?.pair}</b></td>
                    <td>{zeroFormat(((i?.fill / i?.amount) * 100), 0, false)}%</td>
                    <td><div id={i?.status}>{orderStatusFA[i?.status]}</div></td>
                    <td><CgChevronLeftR size={25}
                      className='cPointer btnDetail'
                      onClick={() => dispatch(DialogShow.show(<DetailOrders data={i} deposit={i?.side == "buy" ? true : false} close={() => dispatch(DialogShow.hide())} />))}
                    /></td>
                  </tr>)}
            </tbody>
          </table >
          <FetchListLoad list={listHistory} load={loadHistory} />
        </div >
        <div className='paging'>
          <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReloadApi(new Date().getTime().toString()) }} />
        </div>
      </div>
    </>
  )
}

export default memo(OrderHistory)