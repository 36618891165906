import { Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../../../config/config';
import { toEnglishDigits, zeroFormat } from '../../../../helpers/tools';
import gather from '../../../../helpers/gather';
import _ from 'lodash';
import { orderStatusVoucherFA, sideFA } from '../../../../config/variable';
import { CgChevronLeftR } from 'react-icons/cg';
import DetailVoucherPerfict from '../../dialog/DetailVoucherPerfict';
import * as DialogShow from '../../../../services/redux/dialog';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'jalali-moment';
import FetchListLoad from '../../../../components/FetchListLoad';
import { socket } from '../../../../services/socket';

const HistoryCurrency = ({ reload, setReload }: { reload: any, setReload: any }) => {
  const listVoucherObjID = useSelector((state: any) => state.publicApis?.listVoucherObjID);
  const dispatch = useDispatch<any>();
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  const listVoucherSymbol = useSelector((state: any) => state.publicApis?.listVoucherSymbol);

  const get = async () => {
    setListHistory([])
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/order?sortBy=createdAt&orderBy=desc&limit=20&page=${pageCurrent}&fromDate=${from}&toDate=${to}&coins=${Object.keys(listVoucherObjID).toString().replaceAll(",", "-") ?? ""}`).get();
    // const result = await gather(`${config.api}/v1/order?sortBy=createdAt&orderBy=desc&page=${pageCurrent}&fromDate=${from}&toDate=${to}&coins=${Object.keys(listVoucherObjID).toString().replace(",", "-") ?? ""}`).get();//${findDateQuery({ coins: fID }, false, true)}
    // const result = await gather(`${config.api}/v1/voucher?sortBy=createdAt&orderBy=desc&page=${pageCurrent}&fromDate=${from}&toDate=${to}${findDateQuery({ coin: fID }, false, true)}`).get();

    if (result.message === 200) {
      setListHistory(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  useEffect(() => {
    get();
  }, [reload, pageCurrent])//fID

  useEffect(() => {
    socket?.on('order', get);
    return () => {
      socket?.removeListener('order', get);
    };
  }, []);

  useEffect(() => {
    if (listHistory?.length > 0) {
      const found = Array.from(listHistory)?.find((element: any) => element?.status == "pending");
      if (!_.isEmpty(found)) {
        setTimeout(() => {
          get();
        }, 10000);
      }
    }
  }, [listHistory]);

  return (
    <div className='newHistoryOrder'>
      <div className='tb_opratorBox'>
        <div className='refreshAndInfo mr-auto'>
          <button className='refresh' onClick={() => get()}>
            <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconRefresh.svg" alt="" />
            <p>بروزرسانی</p>
          </button>
        </div>
      </div>
      <div className='tb_View tb_OrderFast'>
        <table>
          <thead>
            <tr>
              <th  className='width_90'><h5>شماره سفارش</h5></th>
              <th><h5>خرید و فروش</h5></th>
              <th  className='width_70'><h5>بازار</h5></th>
              <th className='width_50'><h5>نوع</h5></th>
              <th  className='width_100'><h5>قیمت</h5></th>
              <th  className='width_130'><h5>مقدار</h5></th>
              <th className='width_130'><h5>مقدار نهایی</h5></th>
              {/* <th><h5>مجموع</h5></th > */}
              <th><h5>زمان</h5></th >
              <th><h5>وضعیت</h5></th >
              <th><h5></h5></th>
            </tr >
          </thead >
          <tbody className={"relative"}>
            {(!loadHistory && !_.isEmpty(listHistory))
              && Array.from(listHistory)?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  <td  className='width_90'>{i?.uniqueId}</td>
                  <td className={`color-${i?.side}`}>{sideFA[i?.side] ?? "---"}</td>
                  {/* <td className={`color-${i?.type}`}>{sideFA[i?.type] ?? "---"}</td> */}
                  <td className='width_70'>
                    <div id='name'>
                      <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" />
                      <h5>{i?.coin?.symbol?.toUpperCase()}</h5>
                      {/* <p>({i?.coin?.nameFa ?? "---"})</p> */}
                    </div>
                  </td>
                  <td className='width_50'>فوری</td>
                  <td  className='width_100'>{zeroFormat(i?.price)} <b>{i?.pair} </b></td>
                  <td  className='width_130'>{zeroFormat(i?.amount)}</td>
                  <td className='width_130'>{zeroFormat(i?.final)} <b style={{fontWeight:400}}>{i?.side == "buy" ? i?.coin?.symbol : i?.pair}</b></td>
                  {/* <td>{zeroFormat(i?.amount * i?.price)} <b>{i?.pair}</b></td> */}
                  <td>{i?.createdAt ? toEnglishDigits(moment(i?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')) : "---"}</td>
                  <td><div id={i?.status}>{orderStatusVoucherFA[i?.status]}</div></td>
                  {Object?.values(listVoucherSymbol)?.includes(i?.coin?.symbol)
                    && <td className='cPointer width_60' onClick={() => dispatch(DialogShow.show(<DetailVoucherPerfict data={i} deposit={i?.side == "buy" ? true : false} close={() => dispatch(DialogShow.hide())} />))}>
                      <CgChevronLeftR size={25} />
                    </td>}
                </tr>)}
          </tbody>
        </table >
        <FetchListLoad list={listHistory} load={loadHistory} />
      </div >
      <div className='paging'>
        <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReload(new Date().getTime().toString()) }} />
      </div>
    </div >
  )
}

export default HistoryCurrency