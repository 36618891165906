'use client';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';

const ItemChart = ({ symbol, exchange }: { symbol: any, exchange: any }) => {
  const [changeTheme, setChangeTheme] = useState(false);
  const [loading, setLoading] = useState(true);
  const [theme] = useLocalStorage('themeEX', "light");

  useEffect(() => {
    setLoading(true);
    setChangeTheme(false);
    setTimeout(() => {
      setChangeTheme(true)
    }, 2000);
  }, [theme, symbol]);

  return (
    <>
      {!changeTheme
        ? <></>
        : <div className='tradChart'>
          {loading && <div className='loadingChart'> <CircularProgress size={60} style={{ color: "#2424da" }} /></div>}
          <iframe
            onLoad={(e: any) => setLoading(false)}
            className='iframeTradingViewWidget'
            title={""}
            loading="lazy"
            allowFullScreen
            src={`https://s.tradingview.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22${symbol == "XAUTUSDT" ? "OKX" : exchange}%3A${symbol}%7C1M%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22${theme}%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Atrue%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Atrue%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22no-values%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22headerFontSize%22%3A%22medium%22%2C%22lineWidth%22%3A2%2C%22lineType%22%3A2%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22lineColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%201)%22%2C%22topColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%200)%22%2C%22bottomColor%22%3A%22rgba(144%2C%20191%2C%20249%2C%200.15)%22%2C%22utm_source%22%3A%22exnovin.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22exnovin.net%2Fwidget-wizard%2Fen%2Flight%2Fsymbol-overview%2F%22%7D`}
          />
        </div>
      }
    </>
  );
}

export default ItemChart;
