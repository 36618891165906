import React from 'react';
import Slider from 'react-slick';
import { toFarsiNumber, zeroFormat } from '../../../helpers/tools';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import useTicker from '../../../hooks/useTicker';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { menu } from '../../../config/menu';
import { useSelector } from 'react-redux';

const NewsBox = () => {
  const listCoinObjNEW = useSelector((state: any) => state.publicApis?.listCoinObjNEW);
  const ticker = useTicker();

  const images = [
    "/images/img_s1.png",
    "/images/img_s2.png",
    "/images/img_s3.png"
  ];

  const settingSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:3000,
    arrows: false,
    appendDots: (dots: React.ReactNode) => (
      <div style={{ position: "absolute", bottom: "10px", width: "100%", display: "flex", justifyContent: "center" }}>
        <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
      </div>
    ),
    dotsClass: "slick-dots custom-dots"
  };


  return (
    <div className='newsBox'>
      <div className='cardNewCoin'>
        <div className='boxTitle'>
          <p className='t1'>جدیدترین ارزهای لیست شده</p>
          <span className='icon-treeDot'></span>
        </div>
        <div className='listNewCoin'>

          {Array.from(Object.values(listCoinObjNEW))?.slice(0, 10)?.map((i: any, n: any) =>
            <NavLink to={menu.order.childs.orederPro.url + "/" + i?.symbol?.toUpperCase() + "-TMN"} className='item' key={n}>
              <div className='right'>
                <LazyLoadImage crossOrigin='anonymous' className='icon' src={`${config.imgUrl}${i?.symbol?.toUpperCase()}.png`} alt="" />
                <div className='boxText'>
                  <p className='nameCoin'>{i?.symbol?.toUpperCase()}/TMN</p>
                  <div className='textBox'>
                    <p className='t1'>{zeroFormat(ticker.price(i?.symbol + "tmn"))}</p>
                    <div className='volBox'>
                      <p className={`t2 ltr ${ticker.change(i?.symbol + "tmn") > 0 ? "colorGreen" : "colorRed"}`}>
                        {toFarsiNumber(ticker.change(i?.symbol + "tmn"))}%
                      </p>
                      <span className={`icon-${ticker.change(i?.symbol + "tmn") > 0 ? "upVolume" : "downVolume"}`}></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='left'>
                <img src={`/icons/${ticker.change(i?.symbol + "tmn") > 0 ? "smallChartGreen" : "smallChartRed"}.svg`} alt="" />
              </div>
            </NavLink>
          )}
        </div>
      </div>
      <div className='cartSilder'>
        <Slider {...settingSlider}>
          {images?.map((i: any, n: any) =>
            <div key={n} className='itemImg'>
              <img src={i} alt={`Slide`} className="slide-image" />
            </div>
          )}
        </Slider>
      </div>

    </div>
  );
}

export default NewsBox;
