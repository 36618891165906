import moment from 'jalali-moment'
import React, { useState } from 'react'
import { TextWithLineBreaks } from '../../../helpers/tools'

const ItemNotif = ({ data ,profile}: any) => {
  const [showAll, setShowAll] = useState(false);
  

  return (
    <div onClick={() => setShowAll(!showAll)} className='itemBox'>
      <div className={`item ${ data?.createdAt > profile?.readNotif && "unRead"}`}>
        {/* <h4>{data?.title ?? "- - -"}</h4> */}
        {/* <h5>{(showAll ? data?.text : data?.text?.slice(0, 20)) ?? "- - -"}</h5> */}
        {/* <h5>{data?.text ?? "- - -"}</h5> */}
        <h5>{data?.text ? TextWithLineBreaks(data?.text) : "- - -"}</h5>
        <p>{moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
      </div>
      {/* <span className={`icon-Arrow2${showAll ? "Up" : ""} cPointer`}></span> */}
    </div>
  )
}

export default ItemNotif