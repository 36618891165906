import React, { useRef, useState } from 'react'
import CustomSelectBoxLabel from '../../../components/CustomSelectBoxLabel'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import StepUpgrade from './StepUpgrade';
import * as BackdropShow from '../../../services/redux/backdrop';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { listDocUser } from '../../../config/variable';
import axios from "axios";
import Cookies from 'js-cookie';
import { FaRegTrashCan } from 'react-icons/fa6';
import { BiSolidErrorAlt } from 'react-icons/bi';
import { CircularProgress } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import * as DialogShow from '../../../services/redux/dialog';
import ConfirmUpgrade from '../dialogs/ConfirmUpgrade';
import FaildUpgrade from '../dialogs/FaildUpgrade';
import { getProfile } from '../../../services/redux/user';
import useSnack from '../../../hooks/useSnack';
import heic2any from 'heic2any';

const DocUserUpgrade = ({ send }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [selectItem, setSelectItem]: any = useState(listDocUser[0] ?? {});
  const [statusUplaod, setStatusUplaod] = useState(0);
  const [file, setFile]: any = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [sendOK, setSendOK] = React.useState(false);
  const fileTypes = ["JPG", "PNG"];
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);


  // const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setStatusUplaod(1)
  //   setFile(event);
  
  //   const file:any = event;

  //   if (file && file.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.src = e.target?.result as string;

  //       img.onload = async () => {
  //         const canvas = document.createElement('canvas');
  //         const maxWidth = 800;
  //         const scaleFactor = maxWidth / img.width;
  //         const width = Math.min(maxWidth, img.width);
  //         const height = img.height * scaleFactor;

  //         canvas.width = width;
  //         canvas.height = height;

  //         const ctx = canvas.getContext('2d');
  //         ctx?.drawImage(img, 0, 0, width, height);

  //         canvas.toBlob(
  //           async (blob) => {
  //             if (blob) {
  //               upload(blob)
  //             }
  //           },'image/jpeg',  0.6 );
  //       };
  //     };
  //   }
  // };

  const handleImageUpload = async (event: any) => {
    setStatusUplaod(1)
    setFile(event);
  
    const file:any = event;

    if (!file || !file.type.startsWith('image/')) return;

    let convertedFile = file;

    // **تبدیل HEIC به JPEG**
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      try {
        const blob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.8 });
        convertedFile = new File([blob as Blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(convertedFile);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 1024;  // **حداکثر عرض تصویر**
        const maxHeight = 1024; // **حداکثر ارتفاع تصویر**
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const scaleFactor = Math.min(maxWidth / width, maxHeight / height);
          width = Math.floor(width * scaleFactor);
          height = Math.floor(height * scaleFactor);
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // **فشرده‌سازی و ارسال**
        let quality = 0.8; // کیفیت اولیه
        let blob = await new Promise<Blob | null>((resolve) =>
          canvas.toBlob(resolve, 'image/jpeg', quality)
        );

        // **کاهش حجم تا زیر 600 کیلوبایت**
        while (blob && blob.size > 600 * 1024 && quality > 0.2) {
          quality -= 0.1;
          blob = await new Promise<Blob | null>((resolve) =>
            canvas.toBlob(resolve, 'image/jpeg', quality)
          );
        }

        if (blob) {
          upload(blob);
        }
      };
    };
  };

  const deleteImage = () => {
    setSendOK(false);
    setStatusUplaod(0);
    setFile(null);
    setProgress(0);
  }

  const upload = async (img: any) => {

    const form_data = new FormData();
    form_data.append("type", "document");
    form_data.append("doc", img);

    await axios.post(`${config.api}/v1/file`, form_data, {
      onUploadProgress: (progressEvent: any) => {
        let { loaded, total } = progressEvent;
        let precentage = Math.floor((loaded * 100) / total);
        if (precentage == 100) {
          setProgress(precentage - 14)
        } else {
          setProgress(precentage)
        }
      },
      headers: { 'Authorization': Cookies.get('accessTokenPanelEx') }
    }).then((res: any) => {
      setProgress(100);
      setStatusUplaod(2)
      setSendOK(true);
      setProgress(0);
    }).catch((error: any) => {
      setStatusUplaod(3);
      setProgress(0);
      setSendOK(false);
    });
  }

  const confirm = () => {
    if (sendOK) {
      sendUpgrade();
    } else {
      dispatch(DialogShow.show(<FaildUpgrade text={"کاربر گرامی لطفا مدارک خود را بر اساس نمونه آپلود و ثبت کنید  "}
        close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const sendUpgrade = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/levelUp`).get();

    if (result.message === 200) {
      dispatch(DialogShow.show(<ConfirmUpgrade text={"درخواست شما جهت بررسی ارسال شد"} close={() => dispatch(DialogShow.hide())} />))
      // dispatch(DialogShow.show(<ConfirmUpgrade text={"تبریک ! شما هم اکنون  می توانید از ویژگی های سطح جدید استفاده کنید"} close={() => dispatch(DialogShow.hide())} />))
      send("showAll");
      await dispatch(getProfile());
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <>
      <StepUpgrade step={1} />
      <div className='uploadDoc'>
        {/* <h4 className='warningTxt'>لطفا اطلاعات زیر را <b>مطابق با کارت شناسایی وارد کنید</b></h4> */}
        <h3 className='ntitle mb-15'>کارت شناسایی مورد نظر را انتخاب کنید</h3>
        <CustomSelectBoxLabel flag={false} element={
          <div className='showBanck'>
            <h4 className='p-0 letSpacing0' >{_.isEmpty(selectItem) ? "انتخاب کارت شناسایی" : selectItem?.name}</h4>
          </div>}
          listData={listDocUser} label={""} setInputSatet={setSelectItem} className={"styleBankSelect sortSelect"} />
        <div className='trueTxt'>
          <img src="/icons/m-IconTickSquare2.svg" alt="" />
          <h3 className='ntitle'>نحوه صحیح عکس کارت ملی هوشمند</h3>
        </div>
        <div className='selectFile'>
          <img className='sampleImg' src={selectItem?.img} alt="" />

          <div className="rightItem">
            {/* ClassName : uploading   va   uploaded va "" */}
            <div className={`drag ${statusUplaod === 1 && "uploading"} ${statusUplaod === 2 && "uploaded"} ${statusUplaod === 3 && "errorIamge"}`}>
              <div className="deleteimg" onClick={deleteImage}>
                <FaRegTrashCan size={30} />
              </div>
              <div className="erroring" onClick={deleteImage}>
                <BiSolidErrorAlt color='red' size={30} />
              </div>
              <p className='showProgress textProgress'>{progress}%</p>
              <CircularProgress size={90} variant="determinate" value={progress} className="showProgress" style={{ color: "var(--color-white)" }} />
              <img crossOrigin='anonymous' src={file ? URL.createObjectURL(file) : "/images/sampleimage.jpg"} className="img-fluid imguploaded" alt="" />
              <div className="fileUploader">
                <FileUploader label=""
                  handleChange={handleImageUpload} name="file" types={fileTypes} >
                  <div className='selectBox'>
                    <img src="/icons/m-IconDocUpload.svg" alt="" />
                    <p>عکس مورد نظر خود را بارگزاری کنید</p>
                  </div>
                </FileUploader>
              </div>
            </div>
          </div>
        </div>
        {
          myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button className='finalCheckBtn' onClick={confirm}> تایید </button>
        }
      </div>
    </>
  )
}

export default DocUserUpgrade