import React, { useEffect, useState } from 'react';
import { autoFormatter, formatter, removeComma, toFarsiNumber, toFixed, zeroFormat } from '../../../helpers/tools';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import DonatChart from '../../../components/DonatChart';
import useBalanceFix from '../../../hooks/useBalanceFix';
import { tmnUsdtFilter } from '../../../config/variable';
import { useSelector } from 'react-redux';
import moment from 'jalali-moment';

const CartWallet = ({ profile }: { profile: any }) => {
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [chart, setChart] = useState([{ label: "دارایی", value: 100, color: "#000" }]);
  const [persNumTmn, setPersNumTMN] = useState(0);
  const [persNumUSDT, setPersNumUSDT] = useState(0);
  const balance = useBalanceFix();
  const color = ["#1d6800", "#00ff1a", "#3647ff", "#000000"];
  const listVoucherSymbol = useSelector((state: any) => state.publicApis?.listVoucherSymbol);
  const ticketList = useSelector((state: any) => state.publicApis?.ticketList);
  const cardBanckList = useSelector((state: any) => state.user.cards);
  const RemainderTMN = profile?.plane?.level ? Number(profile?.plane?.level?.withdraw?.fiat?.daily ?? 0) - Number(profile?.limit?.fiat?.daily ?? 0) : 0;
  const RemainderUSDT = profile?.plane?.level ? Number(profile?.plane?.level?.withdraw?.coin?.daily ?? 0) - Number(profile?.limit?.coin?.daily ?? 0) : 0;

  const handleAllAmount = () => {

    let data: any = [{
      key: 1
      , label: "TMN" + "   " + "تومان"
      , value: removeComma(zeroFormat(Number(balance["tmn"]?.value?.usdt), 2, false))
      , color: color[0]
      , month: 'December',
    },
    {
      key: 2
      , label: "USDT" + "   " + "تتر"
      , value: removeComma(zeroFormat(Number(balance["usdt"]?.value.usdt), 2, false))
      , color: color[1]
      , month: 'December',
    },
    {
      key: 3
      , label: "سایر ارزها"
      , value: removeComma(zeroFormat(Object.values(balance)
        ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
        ?.filter((e: any) => !tmnUsdtFilter.includes(e.symbol))
        ?.reduce((n, e: any) => n + e.value.usdt, 0), 2, false))
      , color: color[2]
      , month: 'December',
    }]

    const checkList = data.filter((e: any) => e.value > 0);
    if (checkList?.length > 0) {
      setChart(data)
    }

  }

  const handlePercent = (count: any, total: any) => {
    return 100 - toFixed(Number(Number(count * 100) / Number(total)), 0)
  }

  const handlePecentBalance = () => {
    const allBalanceTmn = Object.values(balance)
      ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
      ?.reduce((n, e: any) => n + e.value.tmn, 0)

    const onlyTmn = Object.values(balance)
      ?.filter((e: any) => e.symbol?.toLowerCase() == "tmn")
      ?.reduce((n, e: any) => n + e.value.tmn, 0)

    const pers = Number(Number(onlyTmn / allBalanceTmn) * 100);
    setPersNumTMN(toFixed(pers ?? 0, 0))


    const allBalanceUsdt = Object.values(balance)
      ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
      ?.reduce((n, e: any) => n + e.value.usdt, 0)

    const onlyTmnUsdt = Object.values(balance)
      ?.filter((e: any) => e.symbol?.toLowerCase() == "tmn")
      ?.reduce((n, e: any) => n + e.value.usdt, 0)

    const persUsdt = Number(Number(Number(allBalanceUsdt - onlyTmnUsdt) / allBalanceUsdt) * 100);
    setPersNumUSDT(toFixed(persUsdt ?? 0, 0))
  }

  useEffect(() => {
    if (userIsLogin) {
      handleAllAmount();
      handlePecentBalance();
    }
  }, []);

  return (
    <div className='cartWallet'>
      <div className='right'>
        <div className='detailWallet'>
          <div className='box1'>
            <p className='tx1'>ارزش دارایی ها</p>
            <p className='tx2'>{toFarsiNumber(formatter(Object.values(balance)
              ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
              ?.reduce((n, e: any) => n + e.value.tmn, 0), 0, 0, true) ?? "")}&nbsp;<b>تومان</b></p>
            <p className='tx3'> معادل <b>{formatter(Object.values(balance)
              ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
              ?.reduce((n, e: any) => n + e.value.usdt, 0), 0, 2, true)}&nbsp;</b>دلار</p>
          </div>
          <div className='box2'>
            <div className="item">
              <div className='prog'>
                <CircularProgressbar
                  value={handlePercent(profile?.limit?.fiat?.daily, profile?.plane?.level?.withdraw?.fiat?.daily)}
                  strokeWidth={13}
                  styles={buildStyles({
                    pathColor: "#22c58b",
                    trailColor: "#d6d6d6",
                  })}
                />
                <span className='icon-smallArrow bgGreen'></span>
              </div>
              <p className='t1'>قابل برداشت امروز</p>
              <p className='t2'>{toFarsiNumber(autoFormatter(RemainderTMN))} <b>تومان</b></p>
            </div>
            <div className="item">
              <div className='prog'>
                <CircularProgressbar
                  value={handlePercent(profile?.limit?.coin?.daily, profile?.plane?.level?.withdraw?.coin?.daily)}
                  strokeWidth={13}
                  styles={buildStyles({
                    pathColor: "#3647ff",
                    trailColor: "#d6d6d6",
                  })}
                />
                <span className='icon-smallArrow'></span>
              </div>
              <p className='t1'>قابل برداشت امروز دلاری</p>
              <p className='t2 t2tmn'>${autoFormatter(RemainderUSDT)}</p>
            </div>
          </div>
        </div>
        <div className='chartWallet'>
          <DonatChart data={chart} />
        </div>
      </div>
      <div className='left'>
        <div className='balance'>
          <div className="item">
            <div className='boxTxt'>
              <p className='t1'>دارایی تومانی</p>
              <p className='t2'>{toFarsiNumber(formatter(Object.values(balance)
                ?.filter((e: any) => e.symbol?.toLowerCase() == "tmn")
                ?.reduce((n, e: any) => n + e.value.tmn, 0), 0, 0, true) ?? "")}</p>
              <p className='t3 '>{moment().locale("fa").format(' D MMMM YYYY')}</p>
            </div>
            <div className='boxProg'>
              <CircularProgressbar
                value={persNumTmn}
                text={`${toFarsiNumber(persNumTmn)}%`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "20px",
                  pathColor: "#22c58b",
                  textColor: "var(--txBody-color)",
                  trailColor: "#d6d6d6",
                })}
              />
            </div>
          </div>
          <div className='hordivider'></div>
          <div className="item">
            <div className='boxTxt'>
              <p className='t1 orangeBefore'>دارایی ارزی</p>
              <p className='t2'>${formatter(Object.values(balance)
                ?.filter((e: any) => e.symbol?.toLowerCase() !== "tmn")
                ?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))
                ?.reduce((n, e: any) => n + e.value.usdt, 0), 0, 2, true)}</p>
              <p className='t3 '>{moment().locale("fa").format(' D MMMM YYYY')}</p>
            </div>
            <div className='boxProg'>
              <CircularProgressbar
                value={persNumUSDT}
                text={`${toFarsiNumber(persNumUSDT)}%`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "20px",
                  pathColor: "#FFB13B",
                  textColor: "var(--txBody-color)",
                  trailColor: "#d6d6d6",
                })}
              />
            </div>
          </div>
        </div>
        <div className='activityUser'>
          <div className='item'>
            <img src="/icons/ticktColor.svg" alt="" className='icon' />
            <div className='boxTxt'>
              <p className='t1'>{toFarsiNumber(Object.keys(ticketList)?.length ?? 0)}</p>
              <p className='t2'>تیکت ها</p>
            </div>
          </div>
          <div className='hordivider'></div>
          <div className='item'>
            <img src="/icons/cardBankColor.svg" alt="" className='icon' />
            <div className='boxTxt'>
              <p className='t1'>{toFarsiNumber(Object.keys(cardBanckList)?.length ?? 0)}</p>
              <p className='t2'>کارت بانکی</p>
            </div>
          </div>
          <div className='hordivider'></div>
          <div className='item'>
            <img src="/icons/rateColor.svg" alt="" className='icon' />
            <div className='boxTxt'>
              <p className='t1'>{profile?.plane?.level?.name ?? "---"}</p>
              <p className='t2'>سطح کاربری</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CartWallet;
