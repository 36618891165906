import { DialogContent } from '@mui/material';
import * as DialogShow from '../../../services/redux/dialog';
import { useDispatch } from 'react-redux';

const DialogEmptyBalance = ({ symbol }: any) => {
  const dispatch = useDispatch();

  return (
    <DialogContent className='phishingWarning'>
      <div className='body'>
        <div className='topTxt'>
          <span className='iconRed-document'></span>
          <p className='t1'>افزایش موجودی {symbol == "tmn" ? "تومان" : symbol?.toUpperCase()}</p>
        </div>
        <div className='divider'></div>
        <div className='warningText'>
            موجودی کیف پول {symbol == "tmn" ? "تومان" : symbol?.toUpperCase()} شما برای این معامله کافی نیست. لطفاً مقدار را بررسی و یا برای افزایش موجودی اقدام کنید.
        </div>
        <div className='btnsTxt'>
        <button className="finalCheckBtn btnCancel2" onClick={() => dispatch(DialogShow.hide())} > انصراف  </button>
          <button className="finalCheckBtn" style={{marginRight:"auto"}} onClick={() => { dispatch(DialogShow.hide()) }}  >افزایش موجودی {symbol == "tmn" ? "تومان" : symbol?.toUpperCase()}</button>
        </div>
      </div>
    </DialogContent>
  )
}

export default DialogEmptyBalance