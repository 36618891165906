import { Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { config } from '../../../config/config';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import gather from '../../../helpers/gather';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { typePaymentFA, sideFA, statusFA } from '../../../config/variable';
import _ from 'lodash';
import { findDateQuery, toEnglishDigits, zeroFormat } from '../../../helpers/tools';
import { CgChevronLeftR } from 'react-icons/cg';
import * as DialogShow from '../../../services/redux/dialog';
import DetailPayment from './DetailPayment';
import useSnack from '../../../hooks/useSnack';
import FetchListLoad from '../../../components/FetchListLoad';

const History = ({ reload, flag, all = false }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  const snackUse = useSnack();


  const get = async () => {
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/payment?sortBy=createdAt&orderBy=desc&page=${pageCurrent}&fromDate=${from}&toDate=${to}${findDateQuery({ side: flag ? "deposit" : "cashout" }, false, true)}`).get()

    if (result.message === 200) {
      setListHistory(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  useEffect(() => {
    get();
  }, [reload, pageCurrent])

  return (
    <div className={`historyBoxAll ${all && "allHistoryStyle"}`}>
      <div className='tb_opratorBox'>
        <h4>آخرین {flag ? "واریز های" : "برداشت های"} تومانی </h4>
        <div className='refreshAndInfo'>
          {/* <div className='info'>
            <p>هنوز {flag ? "واریز " : "برداشت "} نشده؟</p>
            <span className='icon-IconInfoCircle'></span>
          </div> */}
          <button className='refresh' onClick={() => get()}>
            <img crossOrigin='anonymous' src="/icons/m-IconRefresh.svg" alt="" />
            <p>بروزرسانی</p>
          </button>
        </div>
      </div>
      <div className='tb_View tb_Receive'>
        <table>
          <thead>
            <tr>
              <th><h5>شناسه درخواست</h5></th>
              <th><h5>تاریخ و زمان</h5></th>
              <th><h5> نوع تراکنش</h5></th>
              <th><h5>نحوه تراکنش</h5></th>
              <th><h5>مقدار </h5></th>
              <th><h5>کد پیگیری {flag ? "" : "بانکی"}</h5></th>
              <th><h5>وضعیت</h5></th>
              <th><h5></h5></th>
            </tr >
          </thead >
          <tbody className={"relative"}>
            {(!loadHistory && !_.isEmpty(listHistory))
              && Array.from(listHistory)?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  <td>
                    <div className='name'>
                      <CopyToClipboard text={i?.uniqueId} onCopy={() => { i?.uniqueId && snackUse.showSaccess("شناسه درخواست کپی شد") }}>
                        <span className='icon-IconCopy3 cPointer'></span>
                      </CopyToClipboard>
                      <p className='ltr font-14'>{i?.uniqueId}</p>
                    </div>
                  </td>
                  <td> <div className='time'>{toEnglishDigits(moment(i?.createdAt).locale("fa").format('HH:mm | YYYY-MM-DD'))}</div></td>
                  <td className={`color-${i?.side}`}>{sideFA[i?.side]}</td>
                  <td>{typePaymentFA[i?.type]}</td>
                  <td>{zeroFormat(i?.amount)}&nbsp; <span >تومان </span></td>
                  {flag
                    ? <td>
                      {i?.reference
                        ? <div className='name'>
                          <CopyToClipboard text={i?.reference} onCopy={() => { i?.reference && snackUse.showSaccess("کد پیگیری کپی شد") }}>
                            <span className='icon-IconCopy3 cPointer'></span>
                          </CopyToClipboard>
                          <p className='ltr font-14'>{i?.reference}</p>
                        </div>
                        : "---"
                      }
                    </td>
                    : <td>
                      {i?.hash
                        ? <div className='name'>
                          <CopyToClipboard text={i?.hash} onCopy={() => { i?.hash && snackUse.showSaccess("کد پیگیری کپی شد") }}>
                            <span className='icon-IconCopy3 cPointer'></span>
                          </CopyToClipboard>
                          <p className='ltr font-14'>{i?.hash?.length > 18 ? i?.hash?.slice(0, 6) + ". . ." + i?.hash?.slice(-6, i?.hash?.length) : i?.hash}</p>
                        </div>
                        : "---"
                      }
                    </td>
                  }
                  <td><span className={`status ${i?.status}`}>{statusFA[i?.status]}</span></td>
                  <td className='cPointer' onClick={() => dispatch(DialogShow.show(<DetailPayment data={i} deposit={flag} close={() => dispatch(DialogShow.hide())} />))}>
                    <CgChevronLeftR size={25} />
                  </td>
                </tr>)}
          </tbody>
        </table >
        <FetchListLoad list={listHistory} load={loadHistory} />
      </div >
      <div className='paging'>
        <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => setPageCurrent(v)} />
      </div>
    </div>
  )
}

export default History;

