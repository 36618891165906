import { memo, useEffect, useState } from 'react';
import { autoFormatter, priceZeroDecimal, removeComma, zeroFormat } from '../../../../helpers/tools';
import { useSelector } from 'react-redux';

const Depth = ({ selectCoin, orderBookData, bgGreen, setPriceB, setPriceS, setAmountB,
  setAmountS, setTotalS, setTotalB, typePrice, precision, base }: any) => {
  const [dataList, setDataList] = useState([]);
  const [changedRows, setChangedRows] = useState(new Set());
  const [prevData, setPrevData] = useState<any>([]);
  const orderForOderBook = useSelector((state: any) => state.dataSaver?.orderForOderBook);

  const handleArrowOrderBook = (item: any) => {
    let mySide = bgGreen ? "buy" : "sell"
    const findx = Object.keys(orderForOderBook)?.find((e: any) => e == selectCoin?.symbol.toLocaleLowerCase() + "-" + mySide + "-" + item?.price);
    if (findx) {
      return 'arrowOrderBook'
    } else {
      return ""
    }
  }

  const handleClick = (n: any) => {
    if (typePrice == "market") return;

    if (bgGreen) {
      setPriceS(removeComma(handleAvgPrice(n).p2))
      setAmountS(removeComma(handleTotalVolume(n)))
      setTotalS(removeComma(handleTotalVPrice(n)))
    } else {
      setPriceB(removeComma(handleAvgPrice(n).p2))
      setAmountB(removeComma(handleTotalVolume(n)))
      setTotalB(removeComma(handleTotalVPrice(n)))
    }
  }

  const handleAvgPrice = (numI: number) => {
    let number = 0;
    Array.from(dataList)?.reverse()?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += element?.price
      }
    });
    if (number > 0) {
      number = number / (dataList.length - numI);
    }

    let p1 = priceZeroDecimal(number, precision, false);
    let p2 = zeroFormat(number, precision, false);

    return { p1, p2 }
  }

  const handleTotalVolume = (numI: number) => {
    let number = 0;
    Array.from(dataList)?.reverse()?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += element?.amount
      }
    });

    return zeroFormat(number, precision, false)
  }

  const handleTotalVPrice = (numI: number) => {
    let number = 0;
    Array.from(dataList)?.reverse()?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += (Number(element?.price) * Number(element?.amount))
      }
    });

    return zeroFormat(number, precision, false)
  }

  const handleQuantity = (i: any) => {
    const max = Math.max(...dataList.map((e: any) => e?.quantity));
    const min = Math.min(...dataList.map((e: any) => e?.quantity));
    let percent = ((i - min) / (max - min)) * 100;

    if (percent < 1) {
      percent = 1;
    }

    return percent + "%"
  }

  useEffect(() => {
    setDataList(orderBookData ?? [])

    orderBookData.forEach((value: any, n: number) => {
      if (prevData[n] && (value.quantity !== prevData[n].quantity || value.price !== prevData[n].price
        || value.amount !== prevData[n].amount)) {
        setChangedRows((prev) => new Set(prev).add(n));
        setTimeout(() => {
          setChangedRows((prev) => {
            const newSet = new Set(prev);
            newSet.delete(n);
            return newSet;
          });
        }, 300);
      }
    });

    setPrevData(orderBookData);
  }, [orderBookData])

  return <>
    {Array.from(dataList)?.reverse()?.map((i: any, n: any) =>
      <div key={n} className={`tr cPointer ${handleArrowOrderBook(i)}`} onClick={() => handleClick(n)}>
        <div className='td '>
          <p className={`ltr ${bgGreen ? "colorGreen" : "colorRed"}`}>{priceZeroDecimal(i?.price, precision, false)}</p>
        </div>
        <div className='td'><p>{autoFormatter(i?.amount)}</p></div>
        <div className='td'><p>{zeroFormat((Number(i?.price) * Number(i?.amount)), base == "tmn" ? 0 : selectCoin?.decimal, false)}</p></div>
        <div
          className={`progOrder ${bgGreen && "bgGreen2"} ${changedRows.has(n) ? "priceChanged" : ""}`}
          style={{ width: handleQuantity(i?.quantity) }}></div>
        <div className='hoverOrderBook'>
          <div className='itemHover'>
            <p className='p1'>میانگین قیمت : </p>
            <p className='p1 ltr'>{handleAvgPrice(n).p1}</p>
          </div>
          <div className='itemHover'>
            <p className='p1'>حجم کل : </p>
            <p className='p1'>{zeroFormat(Number(removeComma(handleTotalVolume(n))))}</p>
          </div>
          <div className='itemHover'>
            <p className='p1'>ارزش کل : </p>
            <p className='p1'>{zeroFormat(Number(removeComma(handleTotalVPrice(n))))}</p>
          </div>
        </div>
      </div>
    )}
  </>

};

export default memo(Depth);
// export default Depth;
