import { useEffect, useRef, useState } from "react"
import { NavLink, useLocation } from "react-router-dom";
import { show } from "../../services/redux/backdrop";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as DataSaver from "../../services/redux/dataSaver";
import * as dialog from "../../services/redux/dialog";
import NeedLogin from "../NeedLogin";
import { menu } from "../../config/menu";

const NavMenu = ({ title, listNav, icon, active, close, reSize, setOpen, userIsLogin, dispatch, urlList, profile }
  : { title: string, icon: string, listNav: Array<any>, active: string, close: any, reSize: any, setOpen: any, userIsLogin: any, dispatch: any, urlList: any, profile: any }) => {
  const openEle = useSelector((state: any) => state.dataSaver?.showDropDown);
  const location = useLocation();
  const refShowFull: any = useRef(null);
  const refLongText: any = useRef(null);

  const activeLine = (url: string) => {
    return _.startsWith(location.pathname, url) ? "active" : ""
  }

  const showDrop = () => {
    if (openEle.item == title) {
      return !openEle.show
    }
    else {
      return true
    }
  }

  const handleClickAuth = () => {
    close();
    dispatch(dialog.show(<NeedLogin submit={() => { dispatch(dialog.hide()) }} />))
  }

  useEffect(() => {
    const heightLongText = refLongText?.current?.getBoundingClientRect().height;
    if (openEle.item == title) {
      if (openEle.show) {
        refShowFull.current.style.height = `${heightLongText}px`;
      } else {
        refShowFull.current.style.height = "0px";
      }
    } else {
      refShowFull.current.style.height = "0px";
    }
  }, [openEle]);

  useEffect(() => {
    if (!reSize) {
      refShowFull.current.style.height = "0px";
      dispatch(DataSaver.handleMenuDrop({ item: '', show: false }));
    }
  }, [reSize]);

  return (
    <div className={`navMenu`} >
      <div className={`titleMenu ${active}`} onClick={() => { setOpen(true); dispatch(DataSaver.handleMenuDrop({ item: title, show: showDrop() })); }}>
        <div>
          {/* <img crossOrigin='anonymous' src={`/icons/${icon}.svg`} alt="" /> */}
          <span className={icon}></span>
          <p>{title}</p>
        </div>
        <span className={`icon-${(openEle.item == title && openEle.show) ? "ArrowUp" : "ArrowDown"}`}></span>
      </div>
      <div ref={refShowFull} className="subMenu">
        <div ref={refLongText} className="items">
          {Array.from(listNav)?.map((i: any, n: number) => {
            if (!profile?.isOld && menu.order.childs.currency.url == i?.url) return
            if (userIsLogin || urlList.includes(i?.url)) {
              return <NavLink key={n} to={i?.url} onClick={close} className={`linkItem ${activeLine(i.active)}`}>
                <img src="/icons/m-IconCurve.svg" alt="" />
                <p className='subItem'>{i.text}</p>
                {i?.isNew && <div className="styleIsNew">جدید</div>}
              </NavLink>
            } else {
              return <div key={n} onClick={handleClickAuth} className="linkItem">
                <img src="/icons/m-IconCurve.svg" alt="" />
                <p className='subItem'>{i.text}</p>
              </div>
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default NavMenu